import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as localForage from "localforage";

import { AuthVld } from "../../validation";
import { AuthService, UserService } from "../../services";
import { User } from "../../enums";

import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import { UserContext } from "../../context/User.context";
import { SvgIcons } from "../../icons";
import { AgencyContext } from "../../context/Agency.context";
import { TextField } from "@mui/material";

import { UserOutlined } from '@ant-design/icons';
import { Input } from 'antd';

function Signup({ setTitle }) {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const agency = useContext(AgencyContext);


  const [state, setState] = useState({
    loader: false,
    apiError: "",
  });
  const [type, settype] = useState("password");


  const initState = {
    fullName: "",
    email: "",
    password: "",
    // dialingCode: "",
    // phone: "",
  };


  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: AuthVld.SignupVld,
    isInitialValid: false,
  });

  async function handleSubmit(e) {
    console.log('handleSubmit');
    setState({ ...state, loader: true, apiError: "" });

    let names = formik.values.fullName.split(" ");
    let firstName = names.shift();
    let lastName = names.join(" ");
    const payload = {
      firstName: firstName,
      email: formik.values.email,
      password: formik.values.password,
      // dialingCode: formik.values.dialingCode,
      // phone: formik.values.phone,
      role: User.Roles.ADMIN,
      awsEmail: agency?.awsEmail || ""
    };

    if (lastName) payload.lastName = lastName;
    // console.log('!payload', payload);

    const { error, response } = await AuthService.Signup({ payload });
    if (error) return setState({ ...state, apiError: error.error });

    await localForage.setItem("token", response.token);
    const user = await UserService.Get();
    setState({ ...state, loader: false });
    // console.log('user', user);
    if (user.error) return setState({ ...state, apiError: user.error.error });
    // console.log('2nd', user.error.error);

    userContext.updateUser(user.response.data);
    await localForage.setItem('user', user.response.data)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(Object.keys(params).length === 0) history.push('/verify')
    else history.push('/verify?cw')
  }


  console.log('formik ', formik.values);
  console.log('formik Validation', formik.isValid);


  const eyeClick = () => {
    console.log('eye');

    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      settype("password");
    } else {
      x.type = "password";
      settype("text");
    }
  };

  useEffect(() => {
    setTitle("Sign Up");
    console.log('effect');

  }, []);

  const handleChange = async (e, name) => {
    console.log('handleChange');
    formik.setValues({ ...formik.values, [name]: e.target.value });
    formik.setTouched({ ...formik.touched, [name]: true });
  };


  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());


  return (
    <>
      <form onSubmit={formik.handleSubmit} >
        <div className="auth-form" >
          <div className=" Heading24M color-Heading  w-100">
            Transform Your Customer Relationships with Walletly
            {/* Sign up <span className="capitalize">{agency?.agencyName || "Walletly"}</span>! Get started */}
          </div>
          <div className=" Heading16M color-neutral60 w-100">
          Boost cash flow, increase repeat business, and maximize customer lifetime value
          Join over 10,000 successful businesses worldwide
            </div>

            <Input size="large"
              type="email"
              name="email"
              placeholder="Work Email"
              status={formik.errors.email&&"error"}
              value={formik.values.email}
              onChange={(e) => {
                handleChange(e, "email");
              }}
            />
            <Input size="large"
              type="text"
              name="fullName"
              placeholder="Full Name"
              status={formik.errors.fullName&&"error"}
              value={formik.values.fullName}
              onChange={(e) => {
                handleChange(e, "fullName");
              }}
              />

            <Input.Password size="large" 
            status={formik.errors.password&&"error"}
            placeholder="Password"
            name="password"
            value={formik.values.password}
            onChange={(e) => {
              handleChange(e, "password");
            }}
             />


          {/* <div className="w-100 mt_24">
            <CustomTextField
              label="Work Email"
              type="text"
              name="email"
              placeholder="Enter email address"
              background="white"
              className="lh-24"
              fontSize="16px"
              padding="12px 16px"
              value={formik.values.email}
              onChange={(e) => {
                handleChange(e, "email");
              }}
              helperText={formik.touched.email && formik.errors.email}

            />
          </div> */}
          {/* <div className="w-100 ">
            <CustomTextField
              label="Full Name"
              type="text"
              placeholder="Enter full name"
              background="white"
              className="lh-24"
              padding="12px 16px"
              fontSize="16px"
              name="fullName"
              value={formik.values.fullName}
              onChange={(e) => {
                handleChange(e, "fullName");
              }}
              error={formik.touched.fullName && formik.errors.fullName}
              // helperText={
              //   (formik.touched.fullName && formik.errors.fullName)
              //     ? formik.errors.fullName
              //     : ""
              // }
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </div> */}

         
          {/* <div className="d-flex space-between w-100">
            <div className="w-25">
              <CustomTextField
                label="Code"
                type="text"
                name="dialingCode"
                placeholder="61"
                background="white"
                className="lh-24 w-100"
                fontSize="16px"
                padding="24px 16px"
                value={formik.values.dialingCode}
                onChange={(e) => {
                  handleChange(e, "dialingCode");
                }}
                error={formik.touched.dialingCode && formik.errors.dialingCode}
                helperText={
                  formik.touched.dialingCode
                    ? formik.errors.dialingCode
                    : ""
                }
              />
            </div>
            <div className="w-70">
              <CustomTextField
                label="Phone"
                type="number"
                name="phone"
                placeholder="Phone"
                background="white"
                className="lh-24 w-100"
                fontSize="16px"
                padding="24px 16px"
                value={formik.values.phone}
                onChange={(e) => {
                  handleChange(e, "phone");
                }}
                error      = {formik.touched.phone && formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </div>
          </div> */}

          {/* <div className="w-100 position-relative">
            <CustomTextField
              label="Password"
              type="password"
              placeholder="Enter password"
              background="white"
              id="password"
              className="lh-24"
              fontSize="16px"
              padding="12px 16px"
              name="password"
              value={formik.values.password}
              onSubmit={handleSubmit}
              onChange={(e) => {
                handleChange(e, "password");
              }}
              error={formik.touched.password && formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              icon={
                <div className={"cp"} onClick={eyeClick} style={{ position: "absolute", right: "0px" }}>
                  {type !== "password" ? (
                    <SvgIcons.EyeIconPassword />
                  ) : (
                    <SvgIcons.CrossEyeIcon />
                  )}
                </div>
              }
              position="end"
            />

          </div> */}

          {console.log(formik.errors)
          }

          <div className="w-100 pt_8 gap-12 d-flex flex-column">
            <CustomButton
              varient="primary"
              className="w-100 "//hpx-52
              btntext="Sign Up"
              type="submit"
              disabled={state.loader || !formik.isValid || !formik.errors}
              icon={
                state.loader && <CircularProgress size={20} color={"inherit"} />
              }
            />
          </div>
          {state.apiError && <div className={"error"}>{state.apiError}</div>}


          <div className="Body14R  text-center lh-20 color-neutral60">By signing up, I agree to <Link className='Body9R color-primary40 cp' to="privacy" >privacy policy</Link> & <Link className='Body9R color-primary40 cp'  to="terms">terms of service</Link></div>
          
          <div className='d-flex align-items-center w-100 mt_50' style={{ justifyContent: "center" }}>
            <div className="text-center Body14R lh-20 color-neutral70 ">Already have an account?
              <Link to={Object.keys(params).length > 0?'/?cw':'/'} className="Body14R color-primary40 cp ml_10">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Signup;
