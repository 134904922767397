import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import localforage from "localforage";
import { element } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

import CustomButton from "../../../components/CustomButton";
import CustomsModal from "../../../components/CustomModal";
import CustomSearch from "../../../components/CustomSearch";
import CustomSelect from "../../../components/CustomSelect";
import CustomTextField from "../../../components/CustomTextField";
import { AgencyContext } from "../../../context/Agency.context";

import { BrandContext } from "../../../context/Brand.context";
import { LayoutContext } from "../../../context/Layout.context";
import { ToasterContext } from "../../../context/Toaster.context";
import { Brand, GrowthTools } from "../../../enums";
import { PngIcons, SvgIcons } from "../../../icons";
import {
  BrandService,
  CampaignService,
  ChatraceService,
  GhlService,
  ManychatService,
  UchatService,
} from "../../../services";
import EmailServices from "../../../services/EmailServices.service";
import { ClickOutside, utils } from "../../../utils";
import Loader from '../../../components/Loader';

export default function IntegrationNew() {
  const brand = useContext(BrandContext);
  const layout = useContext(LayoutContext);
  const agency = useContext(AgencyContext);
  const toaster = useContext(ToasterContext);

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState();

  const [showDropDown, setShowDropDown] = useState(false);
  const [dropDownLoader, setDropDownLoader] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    layout.setLayout({
      // title       : "Api",
      title: "Settings",
      button: "",
      subTitle: "",
      borderBottom: false,
    });
  }, []);

  const [connected, setConnected] = useState([]);
  const [notConnected, setNotConnected] = useState([]);
  const [filterNotConnected, setFilterNotConnected] = useState([]);

  const handleSearch = (e) => {
    let data = notConnected.filter((element, idx) => {
      if (
        element.name.includes(e.target.value) ||
        element.label.includes(e.target.value)
      ) {
        return true;
      }
    });

    setFilterNotConnected(data);
  };

  const integrations = [
    // {
    //   name: "lightspeed",
    //   label: "Lightspeed",
    //   description: "Power up your POS system by integrating lightspeed.",
    //   image: PngIcons.LightSpeed,
    // },
    // {
    //   name: "smsService",
    //   label: "Twilio",
    //   description:
    //     (brand &&
    //       brand.integrations &&
    //       brand.integrations.smsService &&
    //       brand.integrations.smsService.phoneNumber) ||
    //     "Send SMS to your clients by integrating Twilio services.",
    //   image: PngIcons.TwilioLarge,
    //   component: <SmsService onClose={() => setShow(false)} />,
    // },
    {
      name: "textGrid",
      label: "TextGrid",
      description:
        (brand &&
          brand.integrations &&
          brand.integrations.textgrid &&
          brand.integrations.textgrid.phoneNumber) ||
        "Send SMS to your clients by integrating Text Grid services.",
      image: PngIcons.TextGrid,
      component: <TextGridService onClose={() => setShow(false)} />,
    },
    {
      name: "ghl",
      label: "GHL",
      description:
        (brand &&
          brand.integrations &&
          brand.integrations.ghl &&
          brand.integrations.ghl.apiKey &&
          brand.integrations.ghl.apiKey) ||
        "Power up your CRM by integrating GHL.",
      image: PngIcons.GhlIcon,
      component: <Ghl onClose={() => setShow(false)} />,
    },
    {
      name: "manychat",
      label: "Manychat",
      description:
        brand.integrations?.manychat?.apiKey ||
        "Onboard and engage your clients via ManyChat messenger services.",
      image: PngIcons.ManyChat,
      component: <ManyChat onClose={() => setShow(false)} />,
    },
    // {
    //   name: "uchat",
    //   label: "uchat",
    //   description:
    //     brand.integrations?.uchat?.apiKey ||
    //     "Onboard and engage your clients via UChat messenger services.",
    //   image: PngIcons.UchatIcon,
    //   component: <Uchat onClose={() => setShow(false)} />,
    // },
    // {
    //   name: "chatrace",
    //   label: "Chatrace",
    //   description:
    //     brand.integrations?.chatrace?.apiKey ||
    //     "Onboard and engage your clients via Chatrace messenger services.",
    //   image: PngIcons.Chatrace,
    //   component: <Chatrace onClose={() => setShow(false)} />,
    // },
    // {
    //   name: "emailService",
    //   label: "Email Service",
    //   description:
    //     brand.integrations &&
    //       brand.integrations.emailService &&
    //       brand.integrations.emailService.awsEmail
    //       ? brand.integrations.emailService.awsEmail
    //       : "Send emails to your clients via AWS.",
    //   image: PngIcons.email,
    //   component: <EmailService onClose={() => setShow(false)} />,
    //   agency: agency.whiteLabel,
    // },
    // {
    //   name: "webhook",
    //   label: "Webhook",
    //   description:
    //     brand.integrations &&
    //       brand.integrations.webhook &&
    //       brand.integrations.webhook.url
    //       ? brand.integrations.webhook.url
    //       : "Integrate custom webhook for Zappier.",
    //   image: PngIcons.Webhook,
    //   component: <Webhook onClose={() => setShow(false)} />,
    // },
  ];

  if (brand.integrations && brand.integrations.cloudwaitress && brand.integrations.cloudwaitress.length > 0) {
    brand.integrations.cloudwaitress.map((element, idx) => {
      integrations.push({
        name: "cloudwaitress",
        label: "Cloudwaitress",
        description:
          element.name ||
          "Power up your online ordering platform with cloudwaitress.",
        image: PngIcons.CloudWaitress,
        component: (
          <CloudWaitress
            element={{ ...element, idx: idx }}
            onClose={() => setShow(false)}
          />
        ),
      });
    });
  }

  if ((!brand.integrations) || (brand.integrations && !brand.integrations.cloudwaitress) || (brand.integrations && brand.integrations.cloudwaitress && brand.integrations.cloudwaitress.length === 0)) {
    integrations.push({
      name: "cloudwaitress",
      label: "Cloudwaitress",
      description: "Power up your online ordering platform with cloudwaitress.",
      image: PngIcons.CloudWaitress,
      component: <CloudWaitress onClose={() => setShow(false)} />,
    });
  }

  useEffect(() => {
    let connected = [];
    let notconnected = [];
    integrations.map((element, idx) => {
      if ((brand.integrations && brand.integrations[element.name] && element.name !== 'cloudwaitress') || (element.name === 'cloudwaitress' && (brand.integrations && brand.integrations[element.name] && brand.integrations[element.name].length !== 0))) {
        connected.push({
          ...element,
          integration: { ...brand.integrations[element.name] },
        });
      } else {
        notconnected.push({
          ...element,
          integration: {
            ...(brand.integrations && brand.integrations[element.name]),
          },
        });
      }
    });

    setConnected(connected);
    setNotConnected(notconnected);
    setFilterNotConnected(notconnected);
  }, [show, dropDownLoader]);

  return (
    <div id="integrationNew w-80">
      {/* <div className="mb_12">
        <div className="Heading22M color-neutral100">Connected</div>
      </div>
      <div className="bar"></div> */}

      <div className="connected pb_20">
        {

      connected && connected.length > 0 &&
      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))', 
        gap: '16px' , width: '100%'
      }}>
        {connected.map((value, index) => (
          <Card key={index} sx={{ flex: '1 1 300px' }} 
            onClick={(e) => {
              if (value.component) {
                setShow(true);
                setSelected(value.component);
              }
            }
          }>
            {console.log('value',value)}
            <CardActionArea style={{ alignItems: 'flex-start' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'bold'}}>
                  {utils.capitalize(value.label)}
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2, // Limits to 2 lines
                  textOverflow: 'ellipsis',
                }}>
                  {
                  value.name==='cloudwaitress'?"Power up your online ordering platform with cloudwaitress.":
                  value.name==='ghl'?"Power up your CRM by integrating GHL.":
                  utils.capitalize(value.description)}
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                height="140"
                // width="140px"
                style={{ padding: 12, width: '140px' }}
                image={value.image}
                alt={value.name}
              />
            </CardActionArea>
          </Card>
        ))}
      </div>
        // connected &&
        //   connected.length > 0 &&
        //   connected.map((element, idx) => (
        //     <>
        //       <div
        //         className="row ml_0 mr_0 p_16 align-items-center cp"
                // onClick={(e) => {
                //   if (element.component) {
                //     setShow(true);
                //     setSelected(element.component);
                //   }
                // }}
        //       >
        //         <div className="col-1">
        //           <img src={element.image} width="38px" height="38px" alt="" />
        //         </div>
        //         <div className="col-8">
        //           <div className="Heading15M color-neutral100 capitalize">
        //             {element.label}
        //           </div>
        //           <div className="Link13R color-neutral70 ellipses w-70">
        //             {element.description}
        //           </div>
        //         </div>
                // <div className="col-3">
                //   <ClickOutside
                //     onClick={(e) => {
                //       e.stopPropagation();
                //       setShowDropDown(false);
                //     }}
                //   >
                //     <div
                //       onClick={(e) => {
                //         e.stopPropagation();
                //         setShowDropDown(!showDropDown);
                //         setSelectedIndex(idx);
                //       }}
                //     >
                //       <span className="float-right rotate-left">
                //         <SvgIcons.ViewMoreIcon />
                //       </span>
                //       {showDropDown && selectedIndex === idx && (
                //         <div className="customDropDownDisconnect pt_10 pb_10 pr_8 pl_8">
                //           {element.name === "cloudwaitress" && (
                //             <div
                //               className="pt_10 pb_10 pl_12 pr_12 Body14R color-neutral90 text-center"
                //               onClick={(e) => {
                //                 setSelected(
                //                   <CloudWaitress
                //                     newData={true}
                //                     onClose={() => setShow(false)}
                //                   />
                //                 );
                //                 setShow(true);
                //                 setShowDropDown(!showDropDown);
                //               }}
                //             >
                //               {" "}
                //               Add another restaurant{" "}
                //             </div>
                //           )}
                //           <div
                //             className=" pt_10 pb_10 pl_12 pr_12 Body14R color-danger text-center"
                            // onClick={async (e) => {
                            //   e.stopPropagation();
                            //   // console.log("Delete ");
                            //   setDropDownLoader(true);

                            //   let payload = {
                            //     _id: brand._id,
                            //     integrations: brand.integrations,
                            //     direct: true,
                            //   };

                            //   // console.log('idx ', idx , element);
                            //   if (element.name == 'cloudwaitress') {
                            //     { console.log('integration ', element.integration.length) }
                            //     if (Object.keys(element.integration).length > 0) {
                            //       let newCloudwaitress = [];
                            //       for (let integration of Object.keys(element.integration)) {
                            //         if (element.integration[integration].name != element.description) {
                            //           newCloudwaitress.push(element.integration[integration])
                            //         }
                            //       }
                            //       payload.integrations.cloudwaitress = newCloudwaitress;
                            //     }
                            //     else {
                            //       delete payload.integrations[element.name];
                            //     }
                            //   }
                            //   else {
                            //     delete payload.integrations[element.name];
                            //   }

                            //   // console.log("payload", payload);
                            //   const { error, response } =
                            //     await BrandService.Update({
                            //       toaster: true,
                            //       payload,
                            //     });
                            //   if (response) {
                            //     brand.updateBrand(response.data);
                            //     setShowDropDown(false);
                            //   }
                            //   setDropDownLoader(false);
                            // }}
                //           >
                //             {dropDownLoader ? (
                //               <CircularProgress size={20} color={"inherit"} />
                //             ) : (
                //               "Disconnect Integration"
                //             )}
                //           </div>
                //         </div>
                //       )}
                //     </div>
                //   </ClickOutside>
                // </div>
        //       </div>
        //       <div className="bar"></div>
        //     </>
        //   ))
          }
      </div>

      <div className="notconnected mt_32">
        {/* <div className="w-100 mb_12 row align-items-center">
          <div className="Heading22M color-neutral100 col-4">
            Discover Integrations
          </div>
          <div className="col-4 offset-4 pull">
            <CustomSearch
              className="w-100 "
              placeholder="Search"
              icon={<SvgIcons.SearchIcon />}
              // top={10}
              right={20}
              position={"end"}
              onChange={(e) => handleSearch(e)}
            />
          </div>
        </div> */}
        {/* <div className="bar"></div> */}
        <div
          className="mt_16 d-grid grid-auto mb_16 w-100"
          style={{ gridGap: 16 }}
        >
          {
          
          filterNotConnected && filterNotConnected.length > 0 &&
            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', 
              gap: '16px' , width: '100%'
            }}>
              {filterNotConnected.map((value, index) => (
                <Card key={index} sx={{ flex: '1 1 300px' }} onClick={() => {
                  setShow(true);
                  setSelected(value.component);
                }}>
                  {console.log('index',index)}
                  <CardActionArea style={{ alignItems: 'flex-start' }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'bold'}}>
                        {utils.capitalize(value.label)}
                      </Typography>
                      <Typography variant="h6" color="text.secondary" sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2, // Limits to 2 lines
                        textOverflow: 'ellipsis',
                      }}>
                        {utils.capitalize(value.description)}
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={value.image}
                      alt={value.name}
                    />
                  </CardActionArea>
                </Card>
              ))}
            </div>
          
          
          // filterNotConnected &&
          //   filterNotConnected.length > 0 &&
          //   filterNotConnected.map((element, idx) => (
          //     <div className="">
          //       <div className="pr_16 pt_16">
          //         <div>
          //           <img
          //             src={element.image}
          //             width="32px"
          //             height="32px"
          //             alt=""
          //           />
          //         </div>
          //         <div className=" mt_10 Link14M color-neutral100">
          //           {element.label}
          //         </div>
          //         <div className=" mt_4 Link12R color-neutral60">
          //           {element.description}
          //         </div>
          //         <div className="mt_8">
          //           <CustomButton

          //             // outline={'1px solid transparent'} 
          //             varient="secondaryOutline"
          //             btntext={"Connect"}
          //             className="U14M"
          //             type="submit"
          //             onClick={(e) => {
          //               if (element.component) {
          //                 setShow(true);
          //                 setSelected(element.component);
          //               }
          //             }}
          //           />
          //         </div>
          //       </div>
          //     </div>
          //   ))
            }
        </div>
      </div>
      {show && (
        <CustomsModal
          open={show}
          minWidth={"600px"}
          component={selected}

        // onClose   = {()=> setShow(false)}
        />
      )}
    </div>
  );
}

const ManyChat = ({ onClose }) => {
  const brand = useContext(BrandContext);
  const toaster = useContext(ToasterContext);
  const [show, setShow] = useState({
    loader: false,
    apiExist: false,
    saveLoader: false,
  });

  const [state, setState] = useState({
    apiError: "",
    fields: "",
    manyChatApiKey:
      (brand.integrations &&
        brand.integrations.manychat &&
        brand.integrations[GrowthTools.GrowhttoolTypes.MANYCHAT].apiKey) ||
      "",
  });

  const [manyChatField, setManyChatField] = useState({
    [GrowthTools.ManyChatCustomFields.CURRENTPOINTS]: null,
    // [GrowthTools.ManyChatCustomFields.DEALQRIMAGE]       : null,
    // [GrowthTools.ManyChatCustomFields.DEALIMAGE]         : null,
    // [GrowthTools.ManyChatCustomFields.DEALPAGEURL]       : null,
    [GrowthTools.ManyChatCustomFields.REFERRAL]: null,
    [GrowthTools.ManyChatCustomFields.PASSURL]: null,
    [GrowthTools.ManyChatCustomFields.LASTDEALAVAIL]: null,
    [GrowthTools.ManyChatCustomFields.LASTPOINTS]: null,
    [GrowthTools.ManyChatCustomFields.LASTREDEEMDATE]: null,
    [GrowthTools.ManyChatCustomFields.LASTSPENDINGAMOUNT]: null,
    // [GrowthTools.ManyChatCustomFields.PROMOTION]         : null,
    [GrowthTools.ManyChatCustomFields.TOTALPOINTSUSED]: null,
    [GrowthTools.ManyChatCustomFields.TOTALSPENDING]: null,
    [GrowthTools.ManyChatCustomFields.TOTALVISITS]: null,
    [GrowthTools.ManyChatCustomFields.isWallet]: null,
    [GrowthTools.ManyChatCustomFields.totalReffered]: null,


  });

  const CustomFields = [
    {
      name: GrowthTools.ManyChatCustomFields.CURRENTPOINTS,
    },
    {
      name: GrowthTools.ManyChatCustomFields.REFERRAL,
    },
    {
      name: GrowthTools.ManyChatCustomFields.PASSURL,
    },
    {
      name: GrowthTools.ManyChatCustomFields.LASTDEALAVAIL,
    },
    {
      name: GrowthTools.ManyChatCustomFields.LASTPOINTS,
    },
    {
      name: GrowthTools.ManyChatCustomFields.LASTSPENDINGAMOUNT,
    },
    {
      name: GrowthTools.ManyChatCustomFields.LASTREDEEMDATE,
    },
    {
      name: GrowthTools.ManyChatCustomFields.TOTALVISITS,
    },
    {
      name: GrowthTools.ManyChatCustomFields.TOTALSPENDING,
    },
    {
      name: GrowthTools.ManyChatCustomFields.TOTALPOINTSUSED,
    },
    {
      name: GrowthTools.ManyChatCustomFields.isWallet,
    },
    {
      name: GrowthTools.ManyChatCustomFields.totalReffered,
    },
  ];

  const getCustomField = async ({ apiKey }) => {
    const query = {
      apiKey:
        (brand.integrations &&
          brand.integrations.manychat &&
          brand.integrations.manychat.apiKey) ||
        apiKey,
    };
    const { error, response } = await ManychatService.GetCustomFields({
      query,
    });
    console.log('responsez ', response, error);
    if (response) localforage.setItem("manyChatCustomFields", response.data);
    if (response) return setState({ ...state, fields: response.data });
    if (error) return setState({ ...state, apiError: error.message });


  }

  const getManyChatField = async () => {
    let query = { campaignType: 5, brandId: brand._id };
    const { error, response } = await CampaignService.GetQueriedCampaign({
      query,
    });
    if (response)
      localforage.setItem("manyChatFields", response.data[0].manyChatFields);
    if (response && response.data[0].manyChatFields)
      return setManyChatField(response.data[0].manyChatFields);
  };

  useEffect(async () => {
    setShow({
      ...show,
      loader: true,
      apiExist:
        state.manyChatApiKey && state.manyChatApiKey != "" ? true : false,
    });
    let manyChatCustomFields = await localforage.getItem(
      "manyChatCustomFields"
    );
    if (manyChatCustomFields) {
      await getManyChatField();
      await setState({ ...state, fields: manyChatCustomFields });
    }
    else {
      await getCustomField({ apiKey: state.manyChatApiKey });
      await getManyChatField();
    }
    setShow({
      ...show,
      loader: false,
      apiExist:
        state.manyChatApiKey && state.manyChatApiKey != "" ? true : false,
    });
  }, []);

  const handleSaveFields = async (e) => {
    setShow({ ...show, saveLoader: true });
    let query = { campaignType: 5, brandId: brand._id };
    const { error, response } = await CampaignService.GetQueriedCampaign({
      query,
    });
    if (error) return setShow({ ...show, loader: false });
    let payload = {
      _id: response.data[0]._id,
      manyChatFields: manyChatField,
    };

    const brandPayload = {
      _id: brand._id,
      integrations: {
        manychat: {
          apiKey: state.manyChatApiKey,
        },
      },
    };

    const brandUpdate = await BrandService.Update({ payload: brandPayload });
    brand.updateBrand(brandUpdate.response.data);
    // console.log("brandUpdate ", brandUpdate);

    // console.log("payload is ", payload);

    const updateCampaign = await CampaignService.UpdateCampaign({
      toaster: true,
      payload,
    });
    // console.log("update campaign is ", updateCampaign);
    await localforage.setItem(
      "manyChatFields",
      updateCampaign.response.data.manyChatFields
    );
    // console.log("updateCampaign ", updateCampaign.response.data);
    onClose();
    setShow({ ...show, saveLoader: false });
  };
  // console.log("show is", show);

  const handleSubmit = async () => {
    setShow({ ...show, loader: true });
    const key = GrowthTools.GrowhttoolTypes.MANYCHAT;
    const service = await ManychatService.GetPageInfo({
      query: { apiKey: state.manyChatApiKey },
    });
    if (service.error) {
      setState({ ...state, manyChatApiKey: "", apiError: "Invalid API Key" });
      setShow({ ...show, loader: false });
    } else {
      // console.log("in else");
      const payload = { _id: brand._id };
      if (brand.integrations)
        payload.integrations = {
          ...brand.integrations,
          [key]: { apiKey: state.manyChatApiKey },
        };
      else {
        payload.integrations = { [key]: { apiKey: state.manyChatApiKey } };
      }

      const { error, response } = await BrandService.Update({
        toaster: true,
        payload,
      });
      if (response) {
        brand.updateBrand(response.data);
        await getCustomField({ apiKey: state.manyChatApiKey });
        await getManyChatField({ apiKey: state.manyChatApiKey });
        setShow({ ...show, loader: false, apiExist: true });
      }
    }
  };

  console.log('state == ', state)

  return (
    <div id="integrationComponent">
      <div className="position-absolute closeIcon" onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className="d-flex flex-row align-items-center pb_16">
        <div className="imageIcon">
          <img src={PngIcons.ManyChat} width="32px" height="32px" />
        </div>
        <div className="ml_16">
          <div className="Heading20M color-neutral100">ManyChat</div>
          <div className="Body14R color-neutral60">
            Connect manychat flows by integrating.
          </div>
        </div>
      </div>
      <div className="modalComponent">
        {!show.apiExist && (
          <div className="d-flex space-between mb_16  mt_16 align-items-center">
            <div className="w-100">
              <CustomTextField
                label={"Api Key*"}
                className="w-100"
                onChange={(e) =>
                  setState({
                    ...state,
                    manyChatApiKey: e.target.value,
                    apiError: "",
                  })
                }
                value={state.manyChatApiKey}
                helperText={state.apiError}
              />
            </div>
            <div className="ml_16">
              <CustomButton
                varient="primary"
                btntext={"Validate"}
                size="m"
                disabled={state.manyChatApiKey.length < 1}
                icon={
                  show.loader ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    ""
                  )
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}

        {
          <>
            {show.apiExist && Brand.BrandTypes.NORMAL == brand.brandType && (
              <div className="mappedField w-100 pt_16">
                <>
                  {!show.loader ?
                    CustomFields.map((field, index) => (
                      <div className="d-flex space-between mb_24" key={index}>
                        <div className="middle mr_16 Body14R color-neutral100 capitalize">
                          {field.name}
                        </div>
                        <div className="w-60">
                          {
                            <CustomSelect
                              options={state.fields && state.fields.length > 0 && state.fields}
                              className="w-100 Capitalize"
                              value={manyChatField[field.name]}
                              onChange={(e) => {
                                setManyChatField({
                                  ...manyChatField,
                                  [field.name]: e.target.value,
                                });
                              }}
                            />
                          }
                        </div>
                      </div>
                    ))
                    : <Loader width={'50px'} />
                  }
                </>
              </div>
            )}
          </>
        }
      </div>
      <div className="modalButton d-flex space-between">
        <div className="">
          <CustomButton btntext={"Guidelines"} varient="tertiary" />
        </div>
        {show.apiExist && (
          <div className="">
            <CustomButton
              btntext={"Save Changes"}
              onClick={handleSaveFields}
              icon={
                show.saveLoader ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  ""
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Uchat = ({ onClose }) => {
  const brand = useContext(BrandContext);
  const toaster = useContext(ToasterContext);
  const [show, setShow] = useState({
    loader: false,
    apiExist: false,
    saveLoader: false,
  });

  const [state, setState] = useState({
    apiError: "",
    fields: "",
    uchatApiKey:
      (brand.integrations &&
        brand.integrations.uchat &&
        brand.integrations[GrowthTools.GrowhttoolTypes.UCHAT].apiKey) ||
      "",
  });

  const [uChatFields, setUChatFields] = useState({
    [GrowthTools.UChatCustomFields.CURRENTPOINTS]: null,
    [GrowthTools.UChatCustomFields.LASTDEALAVAIL]: null,
    [GrowthTools.UChatCustomFields.LASTPOINTS]: null,
    [GrowthTools.UChatCustomFields.LASTREDEEMDATE]: null,
    [GrowthTools.UChatCustomFields.LASTSPENDINGAMOUNT]: null,
    [GrowthTools.UChatCustomFields.TOTALPOINTSUSED]: null,
    [GrowthTools.UChatCustomFields.TOTALSPENDING]: null,
    [GrowthTools.UChatCustomFields.TOTALVISITS]: null,
    [GrowthTools.UChatCustomFields.REFERRAL]: null,
    [GrowthTools.UChatCustomFields.PASSURL]: null,
    [GrowthTools.UChatCustomFields.totalReffered]: null,
    [GrowthTools.UChatCustomFields.isWallet]: null,

  });

  const CustomFields = [
    {
      name: GrowthTools.UChatCustomFields.CURRENTPOINTS,
    },
    {
      name: GrowthTools.UChatCustomFields.LASTSPENDINGAMOUNT,
    },
    {
      name: GrowthTools.UChatCustomFields.LASTREDEEMDATE,
    },
    {
      name: GrowthTools.UChatCustomFields.TOTALVISITS,
    },
    {
      name: GrowthTools.UChatCustomFields.TOTALSPENDING,
    },
    {
      name: GrowthTools.UChatCustomFields.LASTDEALAVAIL,
    },
    {
      name: GrowthTools.UChatCustomFields.LASTPOINTS,
    },
    {
      name: GrowthTools.UChatCustomFields.PASSURL,
    },
    {
      name: GrowthTools.UChatCustomFields.TOTALPOINTSUSED,
    },
    {
      name: GrowthTools.UChatCustomFields.isWallet,
    },
    {
      name: GrowthTools.UChatCustomFields.totalReffered,
    },
    {
      name: GrowthTools.UChatCustomFields.REFERRAL,
    },
  ];

  const getCustomField = async (apiKey) => {
    // console.log(" apiKey".apiKey);
    const query = {
      apiKey:
        (brand.integrations &&
          brand.integrations.uchat &&
          brand.integrations.uchat.apiKey) ||
        apiKey,
    };
    const { error, response } = await UchatService.GetCustomFields({ query });
    if (response) localforage.setItem("uChatCustomFields", response.data);
    if (response) return setState({ ...state, fields: response.data });
  };

  const getUChatFields = async () => {
    let query = { campaignType: 5, brandId: brand._id };
    const { error, response } = await CampaignService.GetQueriedCampaign({
      query,
    });
    if (response)
      localforage.setItem("uChatFields", response.data[0].uChatFields);
    if (response && response.data[0].uChatFields)
      return setUChatFields(response.data[0].uChatFields);
  };

  useEffect(async () => {
    // console.log("I m here !");
    setShow({
      ...show,
      apiExist: state.uchatApiKey && state.uchatApiKey != "" ? true : false,
    });
    let uChatCustomFields = await localforage.getItem("uChatCustomFields");
    if (uChatCustomFields) setUChatFields(uChatCustomFields);
    let uChatFields = await localforage.getItem("uChatFields");
    if (uChatFields) setUChatFields(uChatFields);
    getCustomField(state.uchatApiKey);
    getUChatFields();
    setShow({
      ...show,
      apiExist: state.uchatApiKey && state.uchatApiKey != "" ? true : false,
    });
  }, []);

  const handleSaveFields = async (e) => {
    setShow({ ...show, saveLoader: true });
    let query = { campaignType: 5, brandId: brand._id };
    const { error, response } = await CampaignService.GetQueriedCampaign({
      query,
    });
    if (error) return setShow({ ...show, loader: false });
    let payload = {
      _id: response.data[0]._id,
      uChatFields: uChatFields,
    };

    const brandPayload = {
      _id: brand._id,
      integrations: {
        uchat: {
          apiKey: state.uchatApiKey,
        },
      },
    };

    const brandUpdate = await BrandService.Update({ payload: brandPayload });
    brand.updateBrand(brandUpdate.response.data);
    // console.log("brandUpdate ", brandUpdate);

    // console.log("payload is ", payload);

    const updateCampaign = await CampaignService.UpdateCampaign({
      toaster: true,
      payload,
    });
    // console.log("update campaign is ", updateCampaign);
    await localforage.setItem(
      "manyChatFields",
      updateCampaign.response.data.manyChatFields
    );
    // console.log("updateCampaign ", updateCampaign.response.data);
    onClose();
    setShow({ ...show, saveLoader: false });
  };

  const handleSubmit = async () => {
    setShow({ ...show, loader: true });
    // console.log("uchatApiKey", state.uchatApiKey);
    const key = GrowthTools.GrowhttoolTypes.UCHAT;

    const service = await UchatService.GetPageInfo({
      query: { apiKey: state.uchatApiKey },
    });

    if (service.error) {
      setState({ ...state, manyChatApiKey: "", apiError: "Invalid API Key" });
      setShow({ ...show, loader: false });
    } else {
      const payload = { _id: brand._id };
      if (brand.integrations)
        payload.integrations = {
          ...brand.integrations,
          [key]: { apiKey: state.uchatApiKey },
        };
      else {
        payload.integrations = { [key]: { apiKey: state.uchatApiKey } };
      }

      const { error, response } = await BrandService.Update({
        toaster: true,
        payload,
      });
      if (response) {
        brand.updateBrand(response.data);
        await getCustomField({ apiKey: state.uchatApiKey });
        await getUChatFields({ apiKey: state.uchatApiKey });
      }
      setShow({ ...show, loader: false, apiExist: true });
    }
  };

  return (
    <div id="integrationComponent">
      <div className="position-absolute closeIcon" onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className="d-flex flex-row align-items-center pb_16">
        <div className="imageIcon">
          <img src={PngIcons.UchatIcon} width="32px" height="32px" />
        </div>
        <div className="ml_16">
          <div className="Heading20M color-neutral100">Uchat</div>
          <div className="Body14R color-neutral60">
            Connect Uchat flows by integrating.
          </div>
        </div>
      </div>
      <div className="modalComponent">
        {!show.apiExist && (
          <div className="d-flex space-between mb_16 mt_16 align-items-center">
            <div className="w-100">
              <CustomTextField
                label={"Api Key*"}
                className="w-100"
                helperText={state.apiError}
                onChange={(e) =>
                  setState({
                    ...state,
                    uchatApiKey: e.target.value,
                    apiError: "",
                  })
                }
                value={state.uchatApiKey}
              />
            </div>
            <div className="ml_16">
              <CustomButton
                btntext={"Validate"}
                size="m"
                disabled={state.uchatApiKey.length < 1}
                icon={
                  show.loader ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    ""
                  )
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}

        {
          <>
            {show.apiExist && Brand.BrandTypes.NORMAL == brand.brandType && (
              <div className="mappedField w-100 pt_16">
                <>
                  {/* {fields && fields.length > 0  && !loader ? */}
                  {
                    CustomFields.map((field, index) => (
                      <div className="d-flex space-between mb_24" key={index}>
                        <div className="middle mr_16 Body14R color-neutral100 capitalize">
                          {field.name}
                        </div>
                        <div className="w-60">
                          {
                            <CustomSelect
                              options={state.fields &&
                                state.fields.length > 0 && state.fields
                                //  &&
                                // state.fields.map((element) => ({
                                //   name: element.name,
                                //   value: element.name
                                // }))
                              }
                              className="w-100 Capitalize"
                              value={uChatFields[field.name]}
                              onChange={(e) => {
                                setUChatFields({
                                  ...uChatFields,
                                  [field.name]: e.target.value,
                                });
                              }}
                            />
                          }
                        </div>
                      </div>
                    ))
                    // : <Loader width={'50px'}/>
                  }
                </>
              </div>
            )}
          </>
        }
      </div>
      <div className="modalButton d-flex space-between">
        <div className="">
          <CustomButton btntext={"Guidelines"} varient="tertiary" />
        </div>
        {show.apiExist && (
          <div className="">
            <CustomButton
              btntext={"Save Changes"}
              onClick={handleSaveFields}
              icon={
                show.saveLoader ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  ""
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Chatrace = ({ onClose }) => {
  const brand = useContext(BrandContext);
  const toaster = useContext(ToasterContext);
  const [show, setShow] = useState({
    loader: false,
    apiExist: false,
    saveLoader: false,
  });

  const [state, setState] = useState({
    apiError: "",
    fields: "",
    chatraceApiKey:
      (brand.integrations &&
        brand.integrations.chatrace &&
        brand.integrations[GrowthTools.GrowhttoolTypes.CHATRACE].apiKey) ||
      "",
  });

  const [chatraceFields, setChatRaceFields] = useState({
    [GrowthTools.CharaceCustomFields.CURRENTPOINTS]: null,
    [GrowthTools.CharaceCustomFields.LASTDEALAVAIL]: null,
    [GrowthTools.CharaceCustomFields.LASTPOINTS]: null,
    [GrowthTools.CharaceCustomFields.LASTREDEEMDATE]: null,
    [GrowthTools.CharaceCustomFields.LASTSPENDINGAMOUNT]: null,
    [GrowthTools.CharaceCustomFields.TOTALPOINTSUSED]: null,
    [GrowthTools.CharaceCustomFields.TOTALSPENDING]: null,
    [GrowthTools.CharaceCustomFields.TOTALVISITS]: null,
  });

  const CustomFields = [
    {
      name: GrowthTools.CharaceCustomFields.CURRENTPOINTS,
    },
    {
      name: GrowthTools.CharaceCustomFields.LASTSPENDINGAMOUNT,
    },
    {
      name: GrowthTools.CharaceCustomFields.LASTREDEEMDATE,
    },
    {
      name: GrowthTools.CharaceCustomFields.TOTALVISITS,
    },
    {
      name: GrowthTools.CharaceCustomFields.TOTALSPENDING,
    },
    {
      name: GrowthTools.CharaceCustomFields.LASTDEALAVAIL,
    },
    {
      name: GrowthTools.CharaceCustomFields.LASTPOINTS,
    },
    {
      name: GrowthTools.CharaceCustomFields.TOTALPOINTSUSED,
    },
  ];

  const getCustomField = async (apiKey) => {
    // console.log(" apiKey".apiKey);
    const query = {
      apiKey:
        (brand.integrations &&
          brand.integrations.chatrace &&
          brand.integrations.chatrace.apiKey) ||
        apiKey,
    };
    const { error, response } = await ChatraceService.GetCustomFields({
      query,
    });
    // console.log("response is ", response);
    // console.log("error is ", error);
    if (response) localforage.setItem("chatraceCustomFields", response.data);
    if (response) return setState({ ...state, fields: response.data });
    if (error) return setState({ ...state, apiError: error.message });
  };

  const getChatraceFields = async () => {
    let query = { campaignType: 5, brandId: brand._id };
    const { error, response } = await CampaignService.GetQueriedCampaign({
      toaster: true,
      query,
    });
    if (response)
      localforage.setItem("chatraceFields", response.data[0].chatraceFields);
    if (response && response.data[0].chatraceFields)
      return setChatRaceFields(response.data[0].chatraceFields);
  };

  useEffect(async () => {
    // console.log("I m here !");
    setShow({
      ...show,
      apiExist:
        state.chatraceApiKey && state.chatraceApiKey != "" ? true : false,
    });
    let chatraceCustomFields = await localforage.getItem(
      "chatraceCustomFields"
    );
    if (chatraceCustomFields) setChatRaceFields(chatraceCustomFields);
    let chatraceFields = await localforage.getItem("chatraceFields");
    if (chatraceFields) setChatRaceFields(chatraceFields);
    await getCustomField();
    await getChatraceFields();
    setShow({
      ...show,
      apiExist:
        state.chatraceApiKey && state.chatraceApiKey != "" ? true : false,
    });
  }, []);

  const handleSaveFields = async (e) => {
    setShow({ ...show, saveLoader: true });
    let query = { campaignType: 5, brandId: brand._id };
    const { error, response } = await CampaignService.GetQueriedCampaign({
      query,
    });
    if (error) return setShow({ ...show, loader: false });
    let payload = {
      _id: response.data[0]._id,
      chatraceFields: chatraceFields,
    };

    const brandPayload = {
      _id: brand._id,
      integrations: {
        uchat: {
          apiKey: state.chatraceApiKey,
        },
      },
    };

    const brandUpdate = await BrandService.Update({ payload: brandPayload });
    brand.updateBrand(brandUpdate.response.data);
    // console.log("brandUpdate ", brandUpdate);

    // console.log("payload is ", payload);

    const updateCampaign = await CampaignService.UpdateCampaign({
      toaster: true,
      payload,
    });
    // console.log("update campaign is ", updateCampaign);
    await localforage.setItem(
      "chatraceFields",
      updateCampaign.response.data.chatraceFields
    );
    // console.log("updateCampaign ", updateCampaign.response.data);
    onClose();
    setShow({ ...show, saveLoader: false });
  };
  // console.log("show is", show);

  const handleSubmit = async () => {
    // setShow({ ...show, loader: true });
    // // console.log("chatraceApiKey", state.chatraceApiKey);
    // const key = GrowthTools.GrowhttoolTypes.CHATRACE;

    // const service = await CHATR.GetPageInfo({
    //   query: { apiKey: state.chatraceApiKey },
    // });

    // if (service.error) {
    //   setShow({ ...show, loader: false });
    //   setState({ ...state, chatraceApiKey: "" });
    //   toaster.showToaster({
    //     position: "bottom-right",
    //     title: "Error",
    //     message: "invalid key",
    //     severity: "error",
    //   });
    //   return;
    // }

    // const payload = { _id: brand._id };
    // if (brand.integrations)
    //   payload.integrations = {
    //     ...brand.integrations,
    //     [key]: { apiKey: state.chatraceApiKey },
    //   };
    // else {
    //   payload.integrations = { [key]: { apiKey: state.chatraceApiKey } };
    // }

    // const { error, response } = await BrandService.Update({
    //   toaster: true,
    //   payload,
    // });
    // if (response) {
    //   brand.updateBrand(response.data);
    //   await getCustomField({ apiKey: state.chatraceApiKey });
    //   await getChatraceFields({ apiKey: state.chatraceApiKey });
    // }
    // setShow({ ...show, loader: false, apiExist: true });
  };

  return (
    <div id="integrationComponent">
      <div className="position-absolute closeIcon" onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className="d-flex flex-row align-items-center pb_16">
        <div className="imageIcon">
          <img src={PngIcons.Chatrace} width="32px" height="32px" />
        </div>
        <div className="ml_16">
          <div className="Heading20M color-neutral100">Chatrace</div>
          <div className="Body14R color-neutral60">
            Connect Chatrace flows by integrating.
          </div>
        </div>
      </div>
      <div className="modalComponent">
        {!show.apiExist && (
          <div className="d-flex space-between mb_16 mt_16 align-items-center">
            <div className="w-100">
              <CustomTextField
                label={"Api Key*"}
                className="w-100"
                onChange={(e) =>
                  setState({ ...state, chatraceApiKey: e.target.value })
                }
                value={state.chatraceApiKey}
              />
            </div>
            <div className="d-flex flex-column justify-flex-end ml_16">
              <CustomButton
                btntext={"Validate"}
                size="m"
                // disabled={state.chatraceApiKey.length < 1}
                icon={
                  show.loader ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    ""
                  )
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}

        {
          <>
            {show.apiExist && Brand.BrandTypes.NORMAL == brand.brandType && (
              <div className="mappedField w-100 pt_16">
                <>
                  {/* {fields && fields.length > 0  && !loader ? */}
                  {
                    CustomFields.map((field, index) => (
                      <div className="d-flex space-between mb_24" key={index}>
                        <div className="middle mr_16 Body14R color-neutral100 capitalize">
                          {field.name}
                        </div>
                        <div className="w-60">
                          {
                            <CustomSelect
                              options={state.fields &&
                                state.fields.length > 0 && state.fields
                                //  &&
                                // state.fields.map((element) => ({
                                //   name: element.name,
                                //   value: element.name
                                // }))
                              }
                              className="w-100 Capitalize"
                              value={chatraceFields[field.name]}
                              onChange={(e) => {
                                setChatRaceFields({
                                  ...chatraceFields,
                                  [field.name]: e.target.value,
                                });
                              }}
                            />
                          }
                        </div>
                      </div>
                    ))
                    // : <Loader width={'50px'}/>
                  }
                </>
              </div>
            )}
          </>
        }
      </div>
      <div className="modalButton d-flex space-between">
        <div className="">
          <CustomButton btntext={"Guidelines"} varient="tertiary" />
        </div>
        {show.apiExist && (
          <div className="">
            <CustomButton
              btntext={"Save Changes"}
              onClick={handleSaveFields}
              icon={
                show.saveLoader ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  ""
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Ghl = ({ onClose }) => {
  const brand = useContext(BrandContext);
  const toaster = useContext(ToasterContext);
  const [tooltipText, setTooltipText] = useState("copy");
  const [show, setShow] = useState({
    loader: false,
    apiExist: false,
    saveLoader: false,
    webhookUrls: false,
  });

  const [state, setState] = useState({
    apiError: "",
    fields: "",
    ghlApiKey:
      (brand.integrations &&
        brand.integrations.ghl &&
        brand.integrations[GrowthTools.GrowhttoolTypes.GHL].apiKey) ||
      "",
  });

  const [ghlFields, setghlFields] = useState({
    [GrowthTools.GhlCustomFields.CURRENTPOINTS]: null,
    [GrowthTools.GhlCustomFields.LASTDEALAVAIL]: null,
    [GrowthTools.GhlCustomFields.LASTPOINTS]: null,
    [GrowthTools.GhlCustomFields.LASTREDEEMDATE]: null,
    [GrowthTools.GhlCustomFields.LASTSPENDINGAMOUNT]: null,
    [GrowthTools.GhlCustomFields.TOTALPOINTSUSED]: null,
    [GrowthTools.GhlCustomFields.TOTALSPENDING]: null,
    [GrowthTools.GhlCustomFields.TOTALVISITS]: null,
    [GrowthTools.GhlCustomFields.totalReffered]: null,
    [GrowthTools.GhlCustomFields.isWallet]: null,
    [GrowthTools.GhlCustomFields.join_platform]: null,
    [GrowthTools.GhlCustomFields.walletly_pass_url]: null,
    [GrowthTools.GhlCustomFields.walletly_referral_url]: null,

  });

  const CustomFields = [
    {
      name: GrowthTools.GhlCustomFields.CURRENTPOINTS,
    },
    {
      name: GrowthTools.GhlCustomFields.LASTSPENDINGAMOUNT,
    },
    {
      name: GrowthTools.GhlCustomFields.LASTREDEEMDATE,
    },
    {
      name: GrowthTools.GhlCustomFields.TOTALVISITS,
    },
    {
      name: GrowthTools.GhlCustomFields.TOTALSPENDING,
    },
    {
      name: GrowthTools.GhlCustomFields.LASTDEALAVAIL,
    },
    {
      name: GrowthTools.GhlCustomFields.LASTPOINTS,
    },
    {
      name: GrowthTools.GhlCustomFields.TOTALPOINTSUSED,
    },
    {
      name: GrowthTools.GhlCustomFields.isWallet,
    },
    {
      name: GrowthTools.GhlCustomFields.totalReffered,
    },
    {
      name: GrowthTools.GhlCustomFields.join_platform,
    },
    {
      name: GrowthTools.GhlCustomFields.walletly_pass_url,
    },
    {
      name: GrowthTools.GhlCustomFields.walletly_referral_url,
    },
  ];

  const getCustomField = async (apiKey) => {
    // console.log("apiKey", apiKey);
    const query = {
      apiKey:
        (brand.integrations &&
          brand.integrations.ghl &&
          brand.integrations.ghl.apiKey) ||
        apiKey,
    };
    const { error, response } = await GhlService.GetCustomFields({ query });
    if (response) localforage.setItem("ghlCustomFields", response.data);
    if (response) return setState({ ...state, fields: response.data });
    if (error) return setState({ ...state, apiError: error.message });
  };

  const getghlFields = async () => {
    let query = { campaignType: 5, brandId: brand._id };
    const { error, response } = await CampaignService.GetQueriedCampaign({
      toaster: true,
      query,
    });
    if (response) localforage.setItem("ghlFields", response.data[0].ghlFields);
    if (response && response.data[0].ghlFields)
      return setghlFields(response.data[0].ghlFields);
  };

  useEffect(async () => {
    // console.log("I m here !");
    setShow({
      ...show,
      apiExist: state.ghlApiKey && state.ghlApiKey != "" ? true : false,
    });
    let ghlCustomFields = await localforage.getItem("ghlCustomFields");
    if (ghlCustomFields) setghlFields(ghlCustomFields);
    let ghlFields = await localforage.getItem("ghlFields");
    if (ghlFields) setghlFields(ghlFields);
    getCustomField(state.ghlApiKey);
    getghlFields();
    setShow({
      ...show,
      apiExist: state.ghlApiKey && state.ghlApiKey != "" ? true : false,
    });
  }, []);

  const handleSaveFields = async (e) => {
    setShow({ ...show,  webhookUrls: !show.webhookUrls});
    let query = { campaignType: 5, brandId: brand._id };
    const { error, response } = await CampaignService.GetQueriedCampaign({
      query,
    });
    if (error) return setShow({ ...show, loader: false });
    let payload = {
      _id: response.data[0]._id,
      ghlFields: ghlFields,
    };

    const brandPayload = {
      _id: brand._id,
      integrations: {
        ghl: {
          apiKey: state.ghlApiKey,
        },
      },
    };

    const brandUpdate = await BrandService.Update({ payload: brandPayload });
    brand.updateBrand(brandUpdate.response.data);

    const updateCampaign = await CampaignService.UpdateCampaign({
      toaster: true,
      payload,
    });
    // await localforage.setItem('ghlFields', updateCampaign.response.data.ghlFields)
    // console.log("update campaign is ", updateCampaign);
  };

  const handleSubmit = async () => {
    setShow({ ...show, loader: true });
    const key = GrowthTools.GrowhttoolTypes.GHL;

    const service = await GhlService.GetCustomFields({
      query: { apiKey: state.ghlApiKey },
    });
    // console.log("service", service);

    if (service.error) {
      setShow({ ...show, loader: false });
      setState({ ...state, ghlApiKey: "" });
      toaster.showToaster({
        position: "bottom-right",
        title: "Error",
        message: "invalid key",
        severity: "error",
      });
      return;
    }

    const payload = { _id: brand._id };
    if (brand.integrations) {
      payload.integrations = {
        ...brand.integrations,
        [key]: { apiKey: state.ghlApiKey },
      };
      console.log('payload 1', payload);
    }
    else {
      console.log('payload 2', payload);
      payload.integrations = { [key]: { apiKey: state.ghlApiKey } };
    }

    const { error, response } = await BrandService.Update({
      toaster: true,
      payload,
    });
    if (response) {
      brand.updateBrand(response.data);
      await getCustomField(state.ghlApiKey);
      await getghlFields(state.ghlApiKey);
    }

    setShow({ ...show, loader: false, apiExist: true });
  };

  return (
    <div id="integrationComponent">
      <div className="position-absolute closeIcon" onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className="d-flex flex-row align-items-center pb_16">
        <div className="imageIcon">
          <img src={PngIcons.GhlIcon} width="32px" height="32px" />
        </div>
        <div className="ml_16">
          <div className="Heading20M color-neutral100">GHL</div>
          <div className="Body14R color-neutral60">
            Connect GHL flows by integrating.
          </div>
        </div>
      </div>
      <div className="modalComponent">
        {!show.apiExist && (
          <div className="d-flex space-between mb_16 mt_16 align-items-center">
            <div className="w-100">
              <CustomTextField
                label={"Api Key*"}
                className="w-100"
                onChange={(e) =>
                  setState({ ...state, ghlApiKey: e.target.value })
                }
                value={state.ghlApiKey}
              />
            </div>
            <div className="d-flex flex-column justify-flex-end ml_16">
              <CustomButton
                btntext={"Validate"}
                size="m"
                // disabled={state.ghlApiKey.length < 1}
                icon={
                  show.loader ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    ""
                  )
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}

        {
          <>
            {show.apiExist && !show.webhookUrls && 
            // Brand.BrandTypes.NORMAL == brand.brandType && 
            (
              <div className="mappedField w-100 pt_16">
                <>
                  {/* {fields && fields.length > 0  && !loader ? */}
                  {
                    CustomFields.map((field, index) => (
                      <div className="d-flex mb_24 space-between" key={index}>
                        <div className="middle mr_16 Body14R color-neutral100 capitalize">
                        {field.name === 'lastRedeemDate' ? 'LastVisitDate' : field.name}
                        </div>
                        <div className="w-60">
                          {
                            <CustomSelect
                              options={state.fields &&
                                state.fields.length > 0 && state.fields
                                //  &&
                                // state.fields.map((element) => ({
                                //   name: element.name,
                                //   value: element.name
                                // }))
                              }
                              className="w-100 Capitalize"
                              value={ghlFields[field.name]}
                              onChange={(e) => {
                                setghlFields({
                                  ...ghlFields,
                                  [field.name]: e.target.value,
                                });
                              }}
                            />
                          }
                        </div>
                      </div>
                    ))
                    // : <Loader width={'50px'}/>
                  }
                </>
              </div>
            )}
          </>
        }
        {
          <>
            {show.apiExist && show.webhookUrls && 
            // Brand.BrandTypes.NORMAL == brand.brandType && 
            (
              <div className="mappedField w-100 pt_16">
                <>
                  <div>
                    {/* {
                   "https://" +
                   window.location.hostname +
                   "/api/v4/pass/ghlwebhook/" +
                   brand._id +
                   "/" +
                   (brand &&
                     brand.integrations &&
                     brand.integrations.ghl &&
                     brand.integrations.ghl.apiKey &&
                     brand.integrations.ghl.apiKey)
                } */}
                    <div className="copyHead Heading16M color-neutral100 pt_5 pb_5">Webhook For Transfer Data </div>
                    <div>
                      <CustomTextField
                        value={"https://" +
                          window.location.hostname +
                          "/api/v4/pass/ghlwebhook/" +
                          brand._id +
                          "/" +
                          (brand &&
                            brand.integrations &&
                            brand.integrations.ghl &&
                            brand.integrations.ghl.apiKey &&
                            brand.integrations.ghl.apiKey)}
                        icon={<div onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const content =
                            "https://" +
                            window.location.hostname +
                            "/api/v4/pass/ghlwebhook/" +
                            brand._id +
                            "/" +
                            (brand &&
                              brand.integrations &&
                              brand.integrations.ghl &&
                              brand.integrations.ghl.apiKey &&
                              brand.integrations.ghl.apiKey);
                          navigator.clipboard.writeText(content);


                        }}><SvgIcons.CopyIcon /></div>}
                        position='end'
                        paddingRight='35px'


                      />

                    </div>
                    <div className="copyNote Body12R color-neutral60 pt_10 pb_10"> * Please copy the following URL and paste it into GHL to sync new contact in Walletly.</div>
                    <div className="copyHead Heading16M color-neutral100 pt_5 pb_5">Webhook For Contact Update</div>
                    <div>
                      <CustomTextField
                        value={"https://" +
                          window.location.hostname +
                          "/api/v4/pass/ghlwebhook2/" +
                          brand._id +
                          "/" +
                          (brand &&
                            brand.integrations &&
                            brand.integrations.ghl &&
                            brand.integrations.ghl.apiKey &&
                            brand.integrations.ghl.apiKey)}
                        paddingRight='35px'
                        icon={<div onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const content =
                            "https://" +
                            window.location.hostname +
                            "/api/v4/pass/ghlwebhook2/" +
                            brand._id +
                            "/" +
                            (brand &&
                              brand.integrations &&
                              brand.integrations.ghl &&
                              brand.integrations.ghl.apiKey &&
                              brand.integrations.ghl.apiKey);
                          navigator.clipboard.writeText(content);

                        }}><SvgIcons.CopyIcon /></div>}
                        position='end'
                      />
                    </div>
                    <div className="copyNote Body12R color-neutral60 pt_10 pb_10"> * Please copy the following URL and paste it into GHL to update contact in Walletly.</div>

                  </div>
                </>
              </div>
            )}
          </>
        }
      </div>
      <div className="modalButton d-flex space-between">
        <div className="">
          <CustomButton btntext={"Guidelines"} varient="tertiary" />
        </div>
        {show.apiExist && (
          <div className="d-flex">
           {show.webhookUrls && <CustomButton
              btntext={ "Back" }
              varient="secondary"
              onClick={(e) => {
                setShow({ ...show, webhookUrls: !show.webhookUrls })
              }}
            />}
            <CustomButton
              btntext={show.webhookUrls ? "Save Changes" : 'Next'}
              onClick={!show.webhookUrls ? handleSaveFields :onClose }
              className="ml_8"
              icon={
                show.saveLoader ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  ""
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const SmsService = ({ onClose }) => {
  const brand = useContext(BrandContext);
  const [loader, setLoader] = useState(false);
  const [twilio, setTwilio] = useState({
    accountSid:
      brand.integrations && brand.integrations.smsService
        ? brand.integrations.smsService.accountSid
        : "",
    authToken:
      brand.integrations && brand.integrations.smsService
        ? brand.integrations.smsService.authToken
        : "",
    phoneNumber:
      brand.integrations && brand.integrations.smsService
        ? brand.integrations.smsService.phoneNumber
        : "",
  });

  const handleSubmit = async () => {
    let key = "smsService";
    if (twilio.accountSid && twilio.authToken && twilio.phoneNumber) {
      setLoader(true);
      const payload = { _id: brand._id };
      if (brand.integrations)
        payload.integrations = { ...brand.integrations, [key]: twilio };
      else {
        payload.integrations = { [key]: twilio };
      }

      const { error, response } = await BrandService.Update({
        toaster: true,
        payload,
      });
      brand.updateBrand(response.data);
      setLoader(false);
    }
  };

  return (
    <div id="integrationComponent">
      <div className="position-absolute closeIcon" onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className="d-flex flex-row align-items-center pb_16">
        <div className="imageIcon">
          <img src={PngIcons.TwilioLarge} width="32px" height="32px" />
        </div>
        <div className="ml_16">
          <div className="Heading20M color-neutral100">Twilio</div>
          <div className="Body14R color-neutral60">
            Connect twilio by integrating.
          </div>
        </div>
      </div>
      <div className="modalComponent">
        <div className="mt_16 mb_16">
          <div className="w-100">
            <CustomTextField
              label={"Acount Sid *"}
              className="w-100"
              onChange={(e) => {
                setTwilio({ ...twilio, accountSid: e.target.value });
              }}
              value={twilio.accountSid}
            />
          </div>
          <div className="w-100 mt_16">
            <CustomTextField
              label={"Auth Token *"}
              className="w-100"
              type="password"
              onChange={(e) => {
                setTwilio({ ...twilio, authToken: e.target.value });
              }}
              value={twilio.authToken}
            />
          </div>
          <div className="w-100 mt_16">
            <CustomTextField
              label={"Phone Number *"}
              className="w-100"
              onChange={(e) => {
                setTwilio({ ...twilio, phoneNumber: e.target.value });
              }}
              value={twilio.phoneNumber}
            />
          </div>
        </div>
      </div>
      <div className="modalButton d-flex space-between">
        <div className="">
          <CustomButton btntext={"Guidelines"} varient="tertiary" />
        </div>

        <div className="justify-flex-end">
          <CustomButton
            btntext={"Validate"}
            size="m"
            disabled={
              !twilio.accountSid || !twilio.authToken || !twilio.phoneNumber
            }
            icon={
              loader ? <CircularProgress size={18} color={"inherit"} /> : ""
            }
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const TextGridService = ({ onClose }) => {
  const brand = useContext(BrandContext);
  const [loader, setLoader] = useState(false);
  const [textgrid, setTextGrid] = useState({
    accountSid:
      brand.integrations && brand.integrations.textgrid
        ? brand.integrations.textgrid.accountSid
        : "",
    authToken:
      brand.integrations && brand.integrations.textgrid
        ? brand.integrations.textgrid.authToken
        : "",
    phoneNumber:
      brand.integrations && brand.integrations.textgrid
        ? brand.integrations.textgrid.phoneNumber
        : "",
  });

  const handleSubmit = async () => {
    let key = "textGrid";
    if (textgrid.accountSid && textgrid.authToken && textgrid.phoneNumber) {
      setLoader(true);
      const payload = { _id: brand._id };
      if (brand.integrations)
        payload.integrations = { ...brand.integrations, [key]: textgrid };
      else {
        payload.integrations = { [key]: textgrid };
      }


      const { error, response } = await BrandService.Update({
        toaster: true,
        payload,
      });
      brand.updateBrand(response.data);
      setLoader(false);
    }
  };

  return (
    <div id="integrationComponent">
      <div className="position-absolute closeIcon" onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className="d-flex flex-row align-items-center pb_16">
        <div className="imageIcon">
          <img src={PngIcons.TextGrid} width="32px" height="32px" />
        </div>
        <div className="ml_16">
          <div className="Heading20M color-neutral100">Text Grid</div>
          <div className="Body14R color-neutral60">
            Connect text grid by integrating.
          </div>
        </div>
      </div>
      <div className="modalComponent">
        <div className="mt_16 mb_16">
          <div className="w-100">
            <CustomTextField
              label={"Acount Sid *"}
              className="w-100"
              onChange={(e) => {
                setTextGrid({ ...textgrid, accountSid: e.target.value });
              }}
              value={textgrid.accountSid}
            />
          </div>
          <div className="w-100 mt_16">
            <CustomTextField
              label={"Auth Token *"}
              className="w-100"
              type="password"
              onChange={(e) => {
                setTextGrid({ ...textgrid, authToken: e.target.value });
              }}
              value={textgrid.authToken}
            />
          </div>
          <div className="w-100 mt_16">
            <CustomTextField
              label={"Phone Number *"}
              className="w-100"
              onChange={(e) => {
                setTextGrid({ ...textgrid, phoneNumber: e.target.value });
              }}
              value={textgrid.phoneNumber}
            />
          </div>
        </div>
      </div>
      <div className="modalButton d-flex space-between">
        <div className="">
          <CustomButton btntext={"Guidelines"} varient="tertiary" />
        </div>

        <div className="justify-flex-end">
          <CustomButton
            btntext={"Validate"}
            size="m"
            disabled={
              !textgrid.accountSid || !textgrid.authToken || !textgrid.phoneNumber
            }
            icon={
              loader ? <CircularProgress size={18} color={"inherit"} /> : ""
            }
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const EmailService = ({ onClose }) => {
  const brand = useContext(BrandContext);
  const [awsEmail, setAwsEmail] = useState(
    brand.integrations && brand.integrations.emailService
      ? brand.integrations.emailService.awsEmail
      : ""
  );
  const [loader, setLoader] = useState();

  const handleSubmit = async () => {
    let key = "emailService";
    if (awsEmail) {
      // console.log("awsEmail", awsEmail);
      setLoader(true);
      const payload = { _id: brand._id };
      if (brand.integrations) {
        payload.integrations = {
          ...brand.integrations,
          [key]: { awsEmail: awsEmail },
        };
        // console.log("1");
      } else {
        payload.integrations = { [key]: { awsEmail: awsEmail } };
      }

      let payload1 = {
        awsEmail: awsEmail,
      }
      const verifyEmail = await EmailServices.VerifySES({ payload: payload1 });
      // console.log('verifyEmail', verifyEmail);

      const { error, response } = await BrandService.Update({
        toaster: true,
        payload,
      });
      brand.updateBrand(response.data);
      setLoader(false);
    }
  };

  return (
    <div id="integrationComponent">
      <div className="position-absolute closeIcon" onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className="d-flex flex-row align-items-center pb_16">
        <div className="imageIcon">
          <img src={PngIcons.EmailVerify} width="32px" height="32px" />
        </div>
        <div className="ml_16">
          <div className="Heading20M color-neutral100">Email Service</div>
          <div className="Body14R color-neutral60">
            Connect email by integrating.
          </div>
        </div>
      </div>
      <div className="modalComponent">
        <div className="mt_16 mb_16">
          <div className="w-100">
            <CustomTextField
              label={"Email *"}
              className="w-100"
              onChange={(e) => {
                setAwsEmail(e.target.value);
              }}
              value={awsEmail}
              type={"email"}
            />
          </div>
        </div>
      </div>
      <div className="modalButton d-flex space-between">
        <div className="">
          <CustomButton btntext={"Guidelines"} varient="tertiary" />
        </div>

        <div className="justify-flex-end">
          <CustomButton
            btntext={"Validate"}
            size="m"
            icon={
              loader ? <CircularProgress size={18} color={"inherit"} /> : ""
            }
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const Webhook = ({ onClose }) => {
  const brand = useContext(BrandContext);
  const [url, setUrl] = useState(
    brand.integrations && brand.integrations.webhook
      ? brand.integrations.webhook.url
      : ""
  );
  const [loader, setLoader] = useState();

  const handleSubmit = async () => {
    let key = "webhook";
    if (url) {
      setLoader(true);
      const payload = { _id: brand._id };
      if (brand.integrations) {
        payload.integrations = { ...brand.integrations, [key]: { url: url } };
      } else {
        payload.integrations = { [key]: { url: url } };
      }
      const { error, response } = await BrandService.Update({
        toaster: true,
        payload,
      });
      brand.updateBrand(response.data);
      setLoader(false);
    }
  };

  return (
    <div id="integrationComponent">
      <div className="position-absolute closeIcon" onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className="d-flex flex-row align-items-center pb_16">
        <div className="imageIcon">
          <img src={PngIcons.Webhook} width="32px" height="32px" />
        </div>
        <div className="ml_16">
          <div className="Heading20M color-neutral100">Webhook</div>
          <div className="Body14R color-neutral60">
            Connect webhook by integrating.
          </div>
        </div>
      </div>
      <div className="modalComponent">
        <div className="mt_16 mb_16">
          <div className="w-100">
            <CustomTextField
              label={"Email *"}
              className="w-100"
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              value={url}
            />
          </div>
        </div>
      </div>
      <div className="modalButton d-flex space-between">
        <div className="">
          <CustomButton btntext={"Guidelines"} varient="tertiary" />
        </div>

        <div className="justify-flex-end">
          <CustomButton
            btntext={"Validate"}
            size="m"
            icon={
              loader ? <CircularProgress size={18} color={"inherit"} /> : ""
            }
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const CloudWaitress = ({ newData, element, onClose }) => {

  const brand = useContext(BrandContext);
  const [loader, setLoader] = useState();
  const [campaign, setCampaign] = useState();

  const [cloudwaitress, setCloudwaitress] = useState({
    key: element && element.key || '',
    name: element && element.name || '',
    url: element && element.url || '',
    pointsOn: element && element.pointsOn || 'Confirmed',
    PointBaseonAmount: element && element.PointBaseonAmount || 'cart',
    pabblyurl: element && element.pabblyurl || ''
  })

  useEffect(async () => {
    // console.log('sdjbsdhksdjsnkdsdnj')
    const { response, error } = await CampaignService.GetQueriedCampaign({ query: { brandId: brand._id, delete: false, campaignType: 5 } });
    // console.log('response', response, error);
    if (response) setCampaign(response.data[0])
  }, [])

  const handleSubmit = async () => {
    let key = 'cloudwaitress';
    if (cloudwaitress.key && cloudwaitress.name && cloudwaitress.url) {
      setLoader(true)
      const payload = { _id: brand._id };

      if (newData) {
        brand.integrations.cloudwaitress.push(cloudwaitress)
        let data = brand.integrations.cloudwaitress
        // console.log('data', data);
        payload.integrations = { ...brand.integrations, [key]: data }
      }
      else if (element) {
        let data = brand.integrations.cloudwaitress;
        data[element.idx] = cloudwaitress;
        payload.integrations = { ...brand.integrations, [key]: data }
      }
      else if (brand.integrations) payload.integrations = { ...brand.integrations, [key]: [cloudwaitress] }
      else { payload.integrations = { [key]: [cloudwaitress] } }

      const { error, response } = await BrandService.Update({ toaster: true, payload })
      brand.updateBrand(response.data)
      onClose()
      setLoader(false)
    }
  }

  return (
    <div id='integrationComponent'>
      {console.log('onClose', onClose)}
      <div className='position-absolute closeIcon' onClick={onClose}>
        <SvgIcons.CloseIcon width={"32"} height={"32"} />
      </div>
      <div className='d-flex flex-row align-items-center pb_16'>
        <div className='imageIcon'>
          <img src={PngIcons.CloudWaitress} width='32px' height='32px' />
        </div>
        <div className="ml_16">
          <div className='Heading20M color-neutral100'>CloudWaitress</div>
          <div className='Body14R color-neutral60'>Connect to CloudWaitress by integrating.</div>
        </div>
      </div>
      <div className="modalComponent">
        <div className='mt_16 mb_16'>
          <div className="w-100">
            <CustomTextField
              label={"Secret Key *"}
              className="w-100"
              onChange={(e) => { setCloudwaitress({ ...cloudwaitress, key: e.target.value }) }}
              value={cloudwaitress.key}
            />
          </div>
          {cloudwaitress.key && <>
            <div className="w-100 mt_16">
              <CustomTextField
                label={"Restaurant Name *"}
                className="w-100"
                onChange={(e) => { setCloudwaitress({ ...cloudwaitress, name: e.target.value }) }}
                value={cloudwaitress.name}
              />
            </div>
            <div className="w-100 mt_16">
              <CustomTextField
                label={"Restaurant URL *"}
                className="w-100"
                onChange={(e) => { setCloudwaitress({ ...cloudwaitress, url: e.target.value }) }}
                value={cloudwaitress.url}
              />
            </div>
            <div className="w-100-16 mt_16">
              <CustomSelect
                label={"Set Points on*"}
                className="w-100"
                options={['completed', 'confirmed']}
                onChange={(e) => { setCloudwaitress({ ...cloudwaitress, pointsOn: e.target.value }) }}
                value={cloudwaitress.pointsOn}
              />
            </div>
            <div className="w-100-16 mt_16">
              <CustomSelect
                label={"Point Base on Amount"}
                className="w-100"
                options={['cart', 'total']}
                onChange={(e) => { setCloudwaitress({ ...cloudwaitress, PointBaseonAmount: e.target.value }) }}
                value={cloudwaitress.PointBaseonAmount}
              />
            </div>


            <div className="w-100-16 mt_16">
              <CustomTextField
                label={"Pabbly URL"}
                className="w-100"
                onChange={(e) => { setCloudwaitress({ ...cloudwaitress, pabblyurl: e.target.value }) }}
                value={cloudwaitress.pabblyurl}
              />
            </div>
          </>
          }

        </div>
      </div>
      <div className="modalButton d-flex space-between">

        {console.log(campaign)}

        {campaign && cloudwaitress.key && <CustomButton
          btntext="Copy Integration Id"
          varient="secondary"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            const content = "https://" + window.location.hostname + "/api/v4/pass/webhook/" + brand._id + "/" + campaign.campaignCode + "/" + cloudwaitress.key + ""
            navigator.clipboard.writeText(content)
          }}
        />}

        <CustomButton
          btntext="Copy API Key"
          varient="secondary"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            const content = brand.apiKey
            navigator.clipboard.writeText(content)
          }}
        />

        <CustomButton
          btntext="Copy Brand Id"
          varient="secondary"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            const content = brand._id
            navigator.clipboard.writeText(content)
          }}
        />

        <div className='justify-flex-end'>
          <CustomButton
            btntext={"Validate"}
            size="m"
            disabled={!cloudwaitress.key || !cloudwaitress.name || !cloudwaitress.url}
            icon={loader ? <CircularProgress size={18} color={'inherit'} /> : ""}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}