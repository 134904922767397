import React, { useContext, useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { BrandContext } from '../../../context/Brand.context'
import { BrandService, RedeemingWayService } from '../../../services'
import localforage from 'localforage'
import { CircularProgress } from '@mui/material'
import { notification } from 'antd'
import Loader from '../../../components/Loader'

function Notifications() {

    const brand = useContext(BrandContext)

    const [state, setState] = useState({
        loader: true,
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false,
        promoData: {}
    })
    
    const [data, setdata] = useState({
        emailCheck: brand?.emailCheck||false,
        TransactionSmsCheck: brand?.TransactionSmsCheck||false,
        promoCheck: false
    })

    useEffect(async() => {
        const query = { brandId: brand._id, delete: false, redeemingWayType: 'promotion' }
        const redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
        console.log(redeemingWays.response.data[0].notification)
        setdata({...data, promoCheck: redeemingWays.response.data[0].notification})
        setState({...state, promoData: redeemingWays.response.data[0], loader: false})
        // redeemingWays.response.data
    }, [])
    


    const handlePublicProfileUpdateFunc = async () => {
        setState({ generalLoader: true, apiError: '' })

        // const user = await localforage.getItem('user')
        // const permissions = await localforage.getItem('permissions')

        const payload = {
            _id: brand._id,
            emailCheck: data.emailCheck,
            TransactionSmsCheck: data.TransactionSmsCheck,
        }

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        console.log('error ', error);
        console.log('response', response);

        const updateWays = await RedeemingWayService.Update({ payload: {_id: state?.promoData?._id , notification: data.promoCheck} });
        console.log('updateWays',updateWays);
        
        if (error) return setState({ generalLoader: false, brandingLoader: false, apiError: error.error })

        brand.updateBrand(response.data)
        // return setState({ generalLoader: false, apiError: '' })





        
        
    }

  return (
    <>
    {
        state.loader?<Loader />:
        <div style={{width: 'fit-content'}}>
            <div className='d-flex align-items-center space-between mb_8'>
                <div className='Heading22M color-neutral100'>Notifications</div>
                <CustomButton btntext={"Update"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                    setState({ generalLoader: true, apiError: '' })
                    await handlePublicProfileUpdateFunc();
                    setState({ generalLoader: false, apiError: '' })
                }} />
            </div>
            <div className='bar mt_10 mb_20'></div>
            <input type="checkbox" checked={data.emailCheck} onChange={(e)=>{
                if(e.target.checked === false){
                    setdata({...data, emailCheck: e.target.checked, promoCheck: e.target.checked})
                }
                else{
                    setdata({...data, emailCheck: e.target.checked})
                }
                }} />
            <label className='notiText5 ml_10' for="vehicle1"> Turn on Email/SMS notifications</label>
            <div className='bar mt_10 mb_20'></div>
            <div className='row notiText1'>
                <div className='col-5'>NAME</div>
                <div className='col-3 col-end'>EMAIL</div>
                <div className='col-4 col-center'>SMS</div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='notiText2'>Transactional Notification</div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Welcome</div>
                    <div className='notiText3'>Notify customers when they sign up for loyalty; this email/SMS is triggered upon loyalty signup.</div>
                </div>
                <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Points Update</div>
                    <div className='notiText3'>Notify customers when their points balance updates; this email/SMS is triggered upon each transaction.</div>
                </div>
                <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                <div className='col-4 col-center'>
                <input type="checkbox" checked={data.TransactionSmsCheck} onChange={(e)=>{
                        if(data.emailCheck===true)setdata({...data, TransactionSmsCheck: e.target.checked})}
                        } />
                    </div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Redeem</div>
                    <div className='notiText3'>Notify customers when they redeem points for rewards or discounts.</div>
                </div>
                <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Point Expire</div>
                    <div className='notiText3'>Notify customers when their loyalty points are close to expiring, encouraging them to redeem.</div>
                </div>
                <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Inactivity</div>
                    <div className='notiText3'>Notify customers when they’ve been inactive for a while, inviting them back to engage with your brand.</div>
                </div>
                <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='notiText2'>Scheduled Notification</div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Promotion</div>
                    <div className='notiText3'>Notify customers when there’s a promotional offer or special deal available.</div>
                </div>
                <div className='col-3 col-end'>
                    {/* <input className='' checked={data.emailCheck} type="checkbox"/> */}
                    <input type="checkbox" checked={data.promoCheck} onChange={(e)=>{
                        if(data.emailCheck===true)setdata({...data, promoCheck: e.target.checked})}
                        } />
                    </div>
                <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Referral</div>
                    <div className='notiText3'>Notify customers when they can earn rewards for referring friends to your brand.</div>
                </div>
                <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Birthday Followup</div>
                    <div className='notiText3'>Notify customers when it’s time to celebrate their birthday with a follow-up message.</div>
                </div>
                <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
            </div>
            <div className='bar mt_10 mb_20'></div>
            <div className='row'>
                <div className='col-5'>
                    <div className='notiText4'>Birthday Promotion</div>
                    <div className='notiText3'>Notify customers when a special birthday promotion or reward is available to them.</div>
                </div>
                <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
            </div>
            <div className='bar mt_10 mb_20'></div>

        </div>
    }
    </>
  )
}

export default Notifications