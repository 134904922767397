const PngIcons = {
  //svg loader
  banner: "/images/banner.jpg",
  newEWSU: "/images/NewEWBlock.png",
  newEWS: "/images/newEWS.png",
  tick: "/images/tick.png",
  tickG: "/images/tickG.png",
  CWW: "/images/CW-Walletly.png",
  NewIABlock: "/images/NewIABlock.png",
  NewRBlock: "/images/newRefBlock.png",
  NewCWBlock: "/images/newCWBlock.png",
  NewMCBlock: "/images/newMCBlock.png",
  NewGHLBlock: "/images/newGHLBlock.png",
  NewTextGridBlock: "/images/newTextGridBlock.png",
  newBD: "/images/newBD.png",
  newPBlock: "/images/newPBlock.png",
  newRewardBlock: "/images/newRewardBlock.png",
  newshareChannelBlock: "/images/newshareChannelBlock.png",
  newRCBlock: "/images/newRCBlock.png",
  newLPBlock: "/images/newLPBlock.png",
  WidgetBlock: "/images/WidgetBlock.png",
  newCouponBlock: "/images/newCouponBlock.png",
  walletlyLoaderNew: "/images/loader.svg",
  authLogo: "/images/authLogo.png",
  // authLogo: "/images/",
  walletlyLogoFull: "/images/walletlyLogoFull.png",
  newLoader: "/images/newLoader.gif",
  cardSample: "/images/cardSample.png ",
  CloudWaitress: "/images/cloudwaitress.png",
  Confetti: "/images/Confetti.png",
  Alarm: "/images/Alarm.png",
  Logo: "/images/favicon/ManychatLogo1.png",
  Iphone12Pro6: "/images/favicon/loginImage1.png",
  Iphone12Pro7: "/images/favicon/loginImage2.png",
  IconWalletlyOld: "/images/favicon/IconWalletlyOld.png",
  ArrowAlRight: "/images/favicon/arrowaltright.png",
  ManychatLogo1: "/images/favicon/ManychatLogo1.png",
  congretulations: "/images/favicon/congregulations.png",
  SetupPhone: "/images/favicon/setupPhone.png",
  mobileOutlook: "/images/favicon/mobileOutlook-removebg.png",
  outlookBackground: "/images/favicon/outlookBackground.png",
  OutlookLogo: "/images/favicon/OutlookLogo.png",
  cloudWaitressSideImg: '/images/favicon/gradient.png',
  cloudWaitressSideImgSecond: '/images/favicon/gradient2.png',
  earningWaysSide: '/images/favicon/earningWaysSide.png',
  ruleSide: '/images/favicon/rule.png',
  rule1Side: '/images/favicon/rule1.png',
  rule2Side: '/images/favicon/rule2.png',
  rule3: '/images/growthTool/rule3.png',
  bc: '/images/favicon/bc.png',
  mobpass: '/images/favicon/mobpass.png',
  appstore: '/images/favicon/appstore.png',
  playstore: '/images/favicon/playstore.png',
  LandingPagePreview: '/images/favicon/Landingpageview.png',
  ConnectedSideImage: '/images/favicon/ConnectedSideImage.png',
  MediaBlock: '/images/favicon/MediaBlock.png',
  AddReward: '/images/favicon/addReward.png',
  WaystoRedeems: '/images/favicon/WaystoRedeems.png',
  RewardRedemptionLogo: '/images/favicon/RewardRedemption.png',
  ReferralLogo: '/images/favicon/Referral.png',
  BirthdayLogo: '/images/favicon/Birthday.png',
  VipClubimage1: '/images/favicon/VipClubimage6.png',
  VipClubimage2: '/images/favicon/VipClubimage9.png',
  VipClubimageSpe: '/images/image12.png',
  VipClubimage3: '/images/favicon/HalfPizza.png',
  VipClubimage4: '/images/favicon/VipClubimage4.png',
  userAdd: '/images/favicon/userAdd.png',
  notFoundPage: '/images/setting/notFoundPage.png',
  setupComp: '/images/favicon/setupComp.png',
  SignUps: '/images/favicon/SignUps.png',





  Filter: "/images/Filter.png",
  Export: "/images/Export.png",
  Delete: "/images/Delete.png",
  Refresh: "/images/Refresh.png",
  Density: "/images/Density.png",

  walletlyLoader: "/images/walletly.loader.gif",
  logo: "/images/logo_walletly.png",
  example: "/assets/img/example.png",
  ManyChat: "/images/growthTool/manyChat.png",
  Chatrace: "/images/growthTool/chatrace.png",
  email: "/images/setting/aws.png",
  Silferbot: "/images/growthTool/silferBot.png",
  Profile: "/images/profile.png",
  Home: "/images/Home.png",
  line1: "/images/growthTool/line1.png",
  ActiveChat: "/images/growthTool/activeChat.png",
  ChatFuel: "/images/growthTool/chatfuel.png",
  QrCodeGrowth: "/images/growthTool/qrImg.png",
  GhlIcon: "/images/growthTool/ghl.png",
  PopUp: "/images/growthTool/popup.png",
  UchatIcon: "/images/growthTool/Uchat.png",
  TwilioLarge: "/images/growthTool/twilioL.png",
  TwilioLogo: "/images/growthTool/twilioLogo.png",
  TextGrid: "/images/growthTool/textgrid.png",
  DottedLine: "/images/growthTool/dottedline.png",
  Twilio: "/images/growthTool/twilio.png",
  ManyChatLarge: "/images/growthTool/manyChatL.png",
  Barcode: "/images/walletscan/barcode.png",
  EmailVerify: "/images/auth/emailVerify.png",
  signInPhoneImage: "/images/auth/signInPhoneImage.png",
  selfRedemption: "/images/auth/selfredemption.png",
  cashierRedemption: "/images/auth/cashierredemption.png",
  orderRedemption: "/images/auth/orderredemption.png",
  avatarImage: "/images/auth/avatar.png",
  loveAvatar: "/images/auth/loverAvatar.png",
  emptyCover: "/images/auth/emptyCover.png",
  emptyProfile: "/images/auth/emptyProfile.png",
  oldWalletlyLogo: "/images/auth/oldWalletlyLogo.png",
  logoImageBorder: "/images/auth/LogoBorderLogin.png",


  Sidebarbackground: "/images/auth/sidebarbackground",
  WalletlyShadedLogo: "/images/auth/Walletlyshadedlogo.png",
  ColorIcon: "/images/auth/colorIcon",
  GraphBackground: "/images/graphBackground.png",

  CardBuild: "/images/Build.png",
  CardEmail: "/images/Email.png",
  CardGrowth: "/images/GrowthTool.png",
  CardRedemption: "/images/Redemption.png",
  SampleImg: "/images/sampleImg.png",
  SampleImg2: "/images/sampleImg2.png",
  SampleImg3: "/images/sampleImg3.png",

  NotfoundImg: "/images/notfound.png",

  SMSPhoneIcon: "/images/iPhone 12.png",

  //EMPTY STATE IMAGES

  emptyImgRedeem: "/images/imgStop.png",
  btnSetting: "/images/imgSettingButton.png",
  emptyProductListing: "/images/imgPackage.png",

  //loyalty program icons
  spendingIcon: "/images/loyalty/spending.png",
  starIcon: "/images/loyalty/star.png",
  smileIcon: "/images/loyalty/smile.png",
  keyIcon: "/images/loyalty/key.png",
  birthdayIcon: "/images/loyalty/birthday.png",
  dollarCashIcon: "/images/loyalty/dollarcash.png",
  hundredIcon: "/images/loyalty/hundred.png",
  deilveryBoxIcon: "/images/loyalty/deliverybox.png",
  goldMedalIcon: "/images/loyalty/goldmedal.png",
  silverMedalIcon: "/images/loyalty/silvermedal.png",
  bronzeMedalIcon: "/images/loyalty/bronzemedal.png",
  builderIcon: "/images/loyalty/builderIcon.png",
  crownIcon: "/images/loyalty/crownIcon.png",
  starIcon: "/images/loyalty/starIcon.png",
  presentIcon: "/images/loyalty/presentIcon.png",
  designIcon: "/images/loyalty/designIcon.png",
  automationSignup: "/images/favicon/Automation/SignUp.png",
  automationInperson: "/images/favicon/Automation/inPerson.png",
  automationBirthday: "/images/favicon/Automation/birthday.png",
  automationInactivity: "/images/favicon/Automation/Inactivity.png",
  automationReward: "/images/favicon/Automation/Reward.png",
  automationRefferal: "/images/favicon/Automation/Refferal.png",
  automationPromoton: "/images/favicon/Automation/promotion.png",
  automationTags: "/images/favicon/Automation/Tags.png",





  //walletscan Icon

  CustomerInfoEmpty: "/images/walletscan/customerInfoEmpty.png",
  CongratulationsIcon: "/images/walletscan/CongratulationsIcon.png",
  PlaceHolderImage: "/images/walletscan/placeholder.webp",

  //loyalty sidebaricons for formik values beside accordians

  spendingsidebarIcon: "/images/loyalty/spendingsidebar.png",
  smssidebarIcon: "/images/loyalty/smssidebar.png",
  emailsidebarIcon: "/images/loyalty/emailsidebar.png",
  notificationsidebarIcon: "/images/loyalty/notificationsidebar.png",
  chatFlowSidebarIcon: "/images/loyalty/chatFlowSidebar.png",

  //Referral Create new program Icons i.e Reward Page
  MornginBrewIcon: "/images/referral/morningbrew.png",
  AirBnbIcon: "/images/referral/airbnb.png",
  CoinBaseIcon: "/images/referral/coinbase.png",
  DropBoxIcon: "/images/referral/dropbox.png",
  EventBriteIcon: "/images/referral/eventbrite.png",
  GoogleIcon: "/images/referral/google.png",
  UberIcon: "/images/referral/uber.png",

  //designicons
  StarBucksIcon: "/images/design/starbuckslogo.png",
  StarBucksCoverIcon: "/images/design/starbuckcover.png",
  SeaFoodIcon: "/images/design/seafoodlogo.png",
  SeaFoodCoverIcon: "/images/design/seafoodcover.png",
  SmokeGrillLogo: "/images/design/smokegrilllogo.png",
  SmokeGrillCoverIcon: "/images/design/smokegrillcover.png",
  DonutLogoIcon: "/images/design/donutslogo.png",
  DonutCoverIcon: "/images/design/donutscover.png",
  BurgerParkIcon: "/images/design/burgerparklogo.png",
  BurgerParkCoverIcon: "/images/design/burgerparkcover.png",
  DesignQrCode: "/images/design/qrcode.png",
  ApplePassIcon: "/images/design/applepass.png",
  GooglePassIcon: "/images/design/googlepass.png",
  BeastyPassIcon: "/images/design/walletbank.png",

  //IntegrationIcons
  ShopifyIcon: "/images/setting/shopify.png",
  WoocommerceIcon: "/images/setting/woocommerce.png",
  ActivechatIcon: "/images/setting/activechat.png",
  ChatfuelIcon: "/images/setting/chatfuel.png",

  //CommunicationIcons
  GraphIcon: "/images/demograph.png",

  //passesIcons
  WalletPassesIcon: "/images/setting/walletPasses.png",

  //tempraryIcons
  WebsiteTemplateIcon: "/images/websiteTemplate.png",

  //favicon
  favicon: "/images/favicon/favIcon.png",

  //reward builder
  RewardBuilder: {
    earningbirthday: '/images/rewardBuilder/EarningBirthday.png',
    earningspending: '/images/rewardBuilder/EarningSpending.png',
    earningsignup: '/images/rewardBuilder/EarningSignup.png',
    oneTimeDeal: '/images/rewardBuilder/oneTimeDeal.png',
    addPoints: '/images/rewardBuilder/addPoints.png',
    addTag: '/images/rewardBuilder/addTag.png',
    checkin: '/images/rewardBuilder/checkIn.png',
    customerInactivity: '/images/rewardBuilder/customerInactivity.png',
    customerType: '/images/rewardBuilder/customerType.png',
    customerType2: '/images/rewardBuilder/customerType2.png',
    dealStatus: '/images/rewardBuilder/dealStatus.png',
    deductPoints: '/images/rewardBuilder/deductPoints.png',
    event: '/images/rewardBuilder/event.png',
    points: '/images/rewardBuilder/points.png',
    visits: '/images/rewardBuilder/visits.png',
    removeTag: '/images/rewardBuilder/removeTag.png',
    rewardDeal: '/images/rewardBuilder/rewardDeal.png',
    sendMail: '/images/rewardBuilder/sendMail.png',
    sendSMS: '/images/rewardBuilder/sendSMS.png',
    tagCustomer: '/images/rewardBuilder/tagCustomer.png',
    calender: '/images/favicon/Calenders.png',
    spendings: '/images/favicon/Spendings.png',
    doublepoints: '/images/favicon/doublepoints.png',
    sharedLoyality: '/images/favicon/sharedl.png',
    birthDay: '/images/favicon/bday.png',
    actions: '/images/favicon/actions.png'


  },

  //growthtoolIcons
  SmallIcons: {
    SilferbotIcon: "/images/growthTool/smallicons/silferboticon.png",
    QrcodeIcon: "/images/growthTool/smallicons/qrcode.png",
    LandingpageIcon: "/images/growthTool/smallicons/landingpagenew.png",
    AppIcon: "/images/growthTool/smallicons/appIcon.png",
    ApiIcon: "/images/growthTool/smallicons/api.png",
    ActiveChatIcon: "/images/growthTool/smallicons/activechat.png",
    TwilioIcon: "/images/growthTool/smallicons/twilio.png",
    WidgetIcon: "/images/growthTool/smallicons/widget.png",
    DashboardIcon: "/images/growthTool/smallicons/dashboard.png",
    ManyChatIcon: "/images/growthTool/smallicons/manychat.png",
    ChatFuelIcon: "/images/growthTool/smallicons/chatfuel.png",
    WalletlyIcon: "/images/growthTool/smallicons/walletly.png",
  },
  largetIcons: {
    ApiIcon: "/images/growthTool/api.png",
    DashboardIcon: "/images/growthTool/dashboard.png",
    WidgetIcon: "/images/growthTool/widget.png",
    LandingpageIcon: "/images/growthTool/smallicons/landingpagenew.png",
    ChatFuelIcon: "/images/growthTool/chatfuelgrowth.png",
    AppIcon: "/images/growthTool/smallicons/appIcon.png",
    QrCodeIcon: "/images/growthTool/qrgrowth.png",
    ActiveChatIcon: "/images/growthTool/activechatgrowth.png",
    QrLargeIcon: "/images/growthTool/QrBigImage.png",
    PreviewQrImage: "/images/growthTool/previewqrimage.png",
    DemoUserIcon: "/images/growthTool/demouser.png",
    BlockIcon: "/images/growthTool/blockIcon.png",
    ButtonIcon: "/images/growthTool/buttonIcon.png",
    DividerIcon: "/images/growthTool/dividerIcon.png",
    FormIcon: "/images/growthTool/formIcon.png",
    HeadingIcon: "/images/growthTool/headingIcon.png",
    ImageIcon: "/images/growthTool/imageIcon.png",
    InputIcon: "/images/growthTool/inputIcon.png",
    LinkIcon: "/images/growthTool/linkIcon.png",
    ParagraphIcon: "/images/growthTool/paragraphIcon.png",
  },
  // /images/growthTool/linkIcon.png
  Squares: "/images/growthTool/squares.png",
  LandingpageIcon: "/images/growthTool/smallicons/landingpagenew.png",
  AppIcon: "/images/growthTool/appIcon.png",
  landingPageImage: "/images/SideImage.webp",

  Toaster: {
    error: "/images/toaster/Error.png",
    warning: "/images/toaster/Warning.png",
    info: "/images/toaster/Info.png",
    success: "/images/toaster/Success.png",
  },

  PaymentCard: {
    mastercard: "/images/paymentCard/master.png",
    visa: "/images/paymentCard/visa.png",
    default: "/images/paymentCard/default.png",
  },

  passButton: {
    apple: "/images/passesButton/AppleWallet.png",
    beasty: "/images/passesButton/Beasty.png",
    google: "/images/passesButton/GPay.png",
    walletPasses: "/images/passesButton/WalletPasses.png",
  },

  LightSpeed: "/images/growthTool/lightspeed.png",
  Webhook: "/images/setting/webhoook.png",
};

export default PngIcons;
