import React,{useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom';

import PartialSideBar from './PartialSideBar'
import SidebarSub from './SidebarSub';
import SvgIcons from '../../icons/svg.icon';
import GrowthToolChild from './sidebarSubChilds/GrowthToolChild';
import LoyaltyChild from './sidebarSubChilds/LoyaltyChild';
import ProgramChild from './sidebarSubChilds/ProgramChild';
import DashboardChild from './sidebarSubChilds/DashboardChild';
import WalletScanChild from './sidebarSubChilds/WalletScanChild';
import NudgesChild from './sidebarSubChilds/NudgesChild';
import RedemptionChild from './sidebarSubChilds/RedemptionChild';
import ReferralChild from './sidebarSubChilds/ReferralChild';
import SettingChild from './sidebarSubChilds/SettingSubChild';
import ProfileSettingChild from './sidebarSubChilds/ProfileSettingChild';
import CommunicationChild from './sidebarSubChilds/CommunicationChild';
import CampaignsChild from './sidebarSubChilds/CampaignsChild';
import { LayoutContext } from '../../context/Layout.context';
import { Brand, User } from '../../enums';
import { UserContext } from '../../context/User.context';
import { BrandContext } from '../../context/Brand.context';
import { AgencyContext } from '../../context/Agency.context';
import { RedemptionType } from '../../enums/enums';
import localforage from 'localforage';
import { BrandService } from '../../services';

export default function SidebarNav({mobileCheck, setMobileCheck}) {

    const history           = useHistory();
    const layout            = useContext(LayoutContext)
    const activePermission  = useContext(UserContext).activePermission
    const brand             = useContext(BrandContext);
    const agency            = useContext(AgencyContext);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [items, setItems] = useState([
        {
            link      : '/dashboard',
            logo      : SvgIcons.NavDashboardIcon,
            className : 'items middle pt_8',
            name      : 'Dashboard',
            subMenu   : false,
            unique    : 'home',
            access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
            children  : <DashboardChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
        },
        {
            link       : '/audience',
            logo       : SvgIcons.NavAudienceIcon,
            className : 'items middle pt_8',
            // className : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pt_8' : 'items middle pt_8',
            border     : true,
            name      : 'Audience',
            unique    : 'audience',
            subMenu   : false,
            access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
        },
        {
            link      : '/deal',
            logo      : SvgIcons.DealIcon2,
            className : ((brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )) ? 'items middle disabled pt_8' : 'items middle pt_8' ,
            // className : ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pt_8' : 'items middle pt_8' ,
            name      : 'Reward Setup',
            subMenu   : true,
            unique    : 'deal',
            access    : (!brand?.integrations?.cloudwaitress?.length>0) && brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
            // children  : <LoyaltyChild />
        },
        {
            link      : '/loyalty/points/subbrands',
            logo      : SvgIcons.SubbrandIcon,
            // className : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY  ) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pt_8' : 'items middle pt_8' ,
            className : (brand.brandType != Brand.BrandTypes.SHARED_LOYALITY  ) ? 'items middle disabled pt_8' : 'items middle pt_8' ,
            name      : 'Sub Brands',
            subMenu   : true,
            unique    : 'subbrands',
            access    : brand.brandType === Brand.BrandTypes.SHARED_LOYALITY && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
            // children  : <LoyaltyChild />
        },
        {
            link     : '/loyalty/points/tier',
            logo     : SvgIcons.TierIcon,
            className: ( brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) ? 'items middle disabled ': 'items middle ',
            // className: ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled ': 'items middle ',
            name     : brand.tier && 'Loyalty Tier',
            subMenu  : true,
            unique   : 'tier',
            access   : brand.tier && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
            // children  : <LoyaltyChild />
        },
        {
            // link      : '/loyalty',
            link     : '/loyalty/reward',
            logo     : SvgIcons.NavLoyatlyIcon,
            className: (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )  ? 'items middle disabled pb_8': 'items middle pb_8',
            // className: ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pb_8': 'items middle pb_8',
            // name     : 'Loyalty Builder',
            name     : 'Launch Pad',
            subMenu  : true,
            border   : true,
            unique   : 'reward',
            access   : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (!(brand?.integrations?.cloudwaitress?.length>0)),
            // children  : <LoyaltyChild />
        },
        {
            link      : '/loyalty/redeem',
            logo      : SvgIcons.NavLoyatlyIcon,
            className: (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )  ? 'items middle disabled pb_8': 'items middle pb_8',
            // className : ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pb_8' : 'items middle pb_8' ,
            name      : brand?.integrations?.cloudwaitress? "Reward builder" :'Loyalty Builder',
            subMenu   : true,
            border     : brand?.integrations?.cloudwaitress?false:true,
            unique    : 'redeem',
            access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand?.integrations?.cloudwaitress?.length>0),
        },
        
        // {
        //     link      : '/loyalty/design',
        //     logo      : SvgIcons.PassIcon,
        //     className : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled mt_8' : 'items middle mt_8' ,
        //     name      : 'Pass Design',
        //     // border    : true,
        //     unique   : 'design',
        //     subMenu   : true,
        //     access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
        // },
        {
            link      : brand.brandType === Brand.BrandTypes.NORMAL && '/transaction',
            logo      : brand.brandType === Brand.BrandTypes.NORMAL && SvgIcons.NavTransactionIcon,
            className: (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )  ? 'items middle disabled pb_8': 'items middle pb_8',
            // className : ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled  ' : 'items middle ',
            subMenu   : brand.brandType === Brand.BrandTypes.NORMAL && false,
            name      : brand.brandType === Brand.BrandTypes.NORMAL && 'Transaction',
            unique    : 'transaction',
            border: brand?.integrations?.cloudwaitress?true:false,
            access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
            // children  : brand.brandType === Brand.BrandTypes.NORMAL && <GrowthToolChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
        },
        {
            link      : brand.brandType === Brand.BrandTypes.NORMAL && '/growthtools',
            logo      : brand.brandType === Brand.BrandTypes.NORMAL && SvgIcons.NavGrowthIcon,
            className: (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )  ? 'items middle disabled pb_8': 'items middle pb_8',
            // className : ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled  ' : 'items middle ',
            subMenu   : brand.brandType === Brand.BrandTypes.NORMAL && false,
            name      : brand.brandType === Brand.BrandTypes.NORMAL && 'Channels',
            unique    : 'growthtools',
            border: brand?.integrations?.cloudwaitress?true:false,
            access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
            children  : brand.brandType === Brand.BrandTypes.NORMAL && <GrowthToolChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
        },
        {
            link       : '/communication',
            logo       : SvgIcons.NavCommuncationIcon,
            className  : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active') ) ? 'items middle disabled pb_8 ' : 'items middle pb_8 ',
            subMenu    : true,
            border     : false,
            name       : 'Communication',
            unique     : 'communication',
            access     : brand?.integrations?.cloudwaitress?false:activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
            children   : <CommunicationChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
        },
        // {
        //     link       : '/vipclub',
        //     logo       : SvgIcons.VipIcon,
        //     className  : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active') || (!brand.vip) ) ? 'items middle disabled pb_8 ' : 'items middle pb_8 ',
        //     subMenu    : true,
        //     border     : true,
        //     name       : 'VipClub',
        //     unique     : 'vipclub',
        //     access   : brand.vip && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
        // },
        // {
        //     link      : brand.brandType === Brand.BrandTypes.NORMAL && '/automation',
        //     logo      : brand.brandType === Brand.BrandTypes.NORMAL && SvgIcons.AutomationIcon,
        //     activelogo: brand.brandType === Brand.BrandTypes.NORMAL && SvgIcons.FilledAutomationIcon,
        //     className : ((brand.brandType === Brand.BrandTypes.NORMAL && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled' : 'items middle' ,
        //     // className : brand.brandType === Brand.BrandTypes.NORMAL && 'items mt_8 middle' ,
        //     name      : brand.brandType === Brand.BrandTypes.NORMAL && 'Automation',
        //     subMenu   : brand.brandType === Brand.BrandTypes.NORMAL && false,
        //     unique    : 'automation',
        //     access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
        //     // children  : <LoyaltyChild />
        // },
        // {
        //     link      : '/walletscan',
        //     logo      : SvgIcons.WalletScanIcon,
        //     activelogo: SvgIcons.FillNavWalletScanIcon,
        //     // className : 'items middle topMarg mt_8' ,
        //     className : ((brand.brandType === Brand.BrandTypes.NORMAL && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pt_8 pb_8' : 'items middle pt_8 pb_8' ,
        //     border    : true,
        //     subMenu   : true,
        //     name      : 'Wallet-Scan',
        //     unique    : 'walletscan',
        //     access    : activePermission.roles.some(role=>[User.Roles.ADMIN, User.Roles.MARKETER].includes(role)),
        //     // access    : activePermission.roles.some(role=>[User.Roles.ADMIN, User.Roles.MARKETER].includes(role) && ((brand.brandRedemptionType && brand.brandRedemptionType === RedemptionType.CASHIER) || (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY) || (brand.brandType === Brand.BrandTypes.SUB_BRAND))),
        //     children  : <WalletScanChild />
        // },
        {
            link      : '/setting',
            logo      : SvgIcons.NavNewSettingIcon,
            className : 'items middle pt_8',
            subMenu   : true,
            name      : 'Settings',
            unique    : 'setting',
            access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
            children  : <SettingChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
        },
    ])
    const [itemsSub, setItemsSub] = useState([])
    console.log('activePermission',activePermission);

    

    // if(brand.brandType === Brand.BrandTypes.NORMAL){
    //     items.push(
    //         {
    //             link      : '/growthtools',
    //             logo      : SvgIcons.NavGrowthIcon,
    //             className : 'items middle topMarg mt_16',
    //             subMenu   : true,
    //             name      : 'Growth',
    //             access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
    //             children  : <GrowthToolChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
    //         },
    //         {
    //             link      : '/inventory',
    //             logo      : SvgIcons.InventoryIcon,
    //             className : 'items mt_16 middle' ,
    //             name      : 'Inventory',
    //             subMenu   : true,
    //             access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
    //             // children  : <LoyaltyChild />
    //         },
    //         {
    //             link      : '/deal',
    //             logo      : SvgIcons.DealIcon,
    //             className : 'items mt_16 middle' ,
    //             name      : 'Deal',
    //             subMenu   : true,
    //             access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
    //             // children  : <LoyaltyChild />
    //         },
    //     )

    // }

    const onLoad = async() => {
        items.map((element,idx)=>((history.location.pathname.includes(element.link)) && setItemsSub(element.children)))
        // let b = await localforage.getItem('brand')
        // console.log('params',params);
        
        const getBrand = await BrandService.Get({query: {_id: brand._id}})
        localforage.setItem('brand',getBrand.response.data[0])
        if(brand)brand.updateBrand(getBrand.response.data[0])
        
        setItems([
            {
                link      : '/dashboard',
                logo      : SvgIcons.NavDashboardIcon,
                className : 'items middle pt_8',
                name      : 'Dashboard',
                subMenu   : false,
                unique    : 'home',
                access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
                children  : <DashboardChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
            },
            {
                link       : '/audience',
                logo       : SvgIcons.NavAudienceIcon,
                className : 'items middle pt_8',
                // className : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pt_8' : 'items middle pt_8',
                border     : true,
                name      : 'Audience',
                unique    : 'audience',
                subMenu   : false,
                access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
            },
            {
                link      : '/deal',
                logo      : SvgIcons.DealIcon2,
                className : ((brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )) ? 'items middle disabled pt_8' : 'items middle pt_8' ,
                // className : ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pt_8' : 'items middle pt_8' ,
                name      : 'Reward Setup',
                subMenu   : true,
                unique    : 'deal',
                access    : 
                (!getBrand?.response?.data[0]?.integrations?.cloudwaitress?.length>0) && 
                // Object.keys(params).length === 0 &&
                brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
                // children  : <LoyaltyChild />
            },
            {
                link      : '/loyalty/points/subbrands',
                logo      : SvgIcons.SubbrandIcon,
                // className : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY  ) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pt_8' : 'items middle pt_8' ,
                className : (brand.brandType != Brand.BrandTypes.SHARED_LOYALITY  ) ? 'items middle disabled pt_8' : 'items middle pt_8' ,
                name      : 'Sub Brands',
                subMenu   : true,
                unique    : 'subbrands',
                access    : brand.brandType === Brand.BrandTypes.SHARED_LOYALITY && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
                // children  : <LoyaltyChild />
            },
            {
                link     : '/loyalty/points/tier',
                logo     : SvgIcons.TierIcon,
                className: ( brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) ? 'items middle disabled ': 'items middle ',
                // className: ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled ': 'items middle ',
                name     : brand.tier && 'Loyalty Tier',
                subMenu  : true,
                unique   : 'tier',
                access   : brand.tier && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
                // children  : <LoyaltyChild />
            },
            {
                // link      : '/loyalty',
                link     : '/loyalty/reward',
                logo     : SvgIcons.NavLoyatlyIcon,
                className: (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )  ? 'items middle disabled pb_8': 'items middle pb_8',
                // className: ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pb_8': 'items middle pb_8',
                name     : 'Launch Pad',
                // name     : 'Loyalty Builder',
                subMenu  : true,
                border   : true,
                unique   : 'reward',
                access   : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (!(getBrand?.response?.data[0]?.integrations?.cloudwaitress?.length>0)),
                // children  : <LoyaltyChild />
            },
            {
                link      : '/loyalty/redeem',
                logo      : SvgIcons.NavLoyatlyIcon,
                className: (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )  ? 'items middle disabled pb_8': 'items middle pb_8',
                // className : ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pb_8' : 'items middle pb_8' ,
                name      : getBrand?.response?.data[0]?.integrations?.cloudwaitress? "Launch Pad" :'Launch Pad',
                // name      : getBrand?.response?.data[0]?.integrations?.cloudwaitress? "Reward builder" :'Loyalty Builder',
                subMenu   : true,
                border     : getBrand?.response?.data[0]?.integrations?.cloudwaitress?false:true,
                unique    : 'redeem',
                access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (getBrand?.response?.data[0]?.integrations?.cloudwaitress?.length>0),
            },
            
            // {
            //     link      : '/loyalty/design',
            //     logo      : SvgIcons.PassIcon,
            //     className : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled mt_8' : 'items middle mt_8' ,
            //     name      : 'Pass Design',
            //     // border    : true,
            //     unique   : 'design',
            //     subMenu   : true,
            //     access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
            // },
            {
                link      : brand.brandType === Brand.BrandTypes.NORMAL && '/transaction',
                logo      : brand.brandType === Brand.BrandTypes.NORMAL && SvgIcons.NavTransactionIcon,
                className: (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )  ? 'items middle disabled pb_8': 'items middle pb_8',
                // className : ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled  ' : 'items middle ',
                subMenu   : brand.brandType === Brand.BrandTypes.NORMAL && false,
                name      : brand.brandType === Brand.BrandTypes.NORMAL && 'Transaction',
                unique    : 'transaction',
                border: brand?.integrations?.cloudwaitress?true:false,
                access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
                // children  : brand.brandType === Brand.BrandTypes.NORMAL && <GrowthToolChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
            },
            {
                link      : brand.brandType === Brand.BrandTypes.NORMAL && '/growthtools',
                logo      : brand.brandType === Brand.BrandTypes.NORMAL && SvgIcons.NavGrowthIcon,
                className: (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY )  ? 'items middle disabled pb_8': 'items middle pb_8',
                // className : ((brand.brandType != Brand.BrandTypes.SHARED_LOYALITY && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled  ' : 'items middle ',
                subMenu   : brand.brandType === Brand.BrandTypes.NORMAL && false,
                name      : brand.brandType === Brand.BrandTypes.NORMAL && 'Channels',
                unique    : 'growthtools',
                border: getBrand?.response?.data[0]?.integrations?.cloudwaitress?true:false,
                access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
                children  : brand.brandType === Brand.BrandTypes.NORMAL && <GrowthToolChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
            },
            {
                link       : '/communication',
                logo       : SvgIcons.NavCommuncationIcon,
                className  : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active') ) ? 'items middle disabled pb_8 ' : 'items middle pb_8 ',
                subMenu    : true,
                border     : false,
                name       : 'Communication',
                unique     : 'communication',
                access     : getBrand?.response?.data[0]?.integrations?.cloudwaitress ? false : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
                children   : <CommunicationChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
            },
            // {
            //     link       : '/vipclub',
            //     logo       : SvgIcons.VipIcon,
            //     className  : ((!brand.subscription && brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) || (brand.subscription && brand.subscription.status !== 'active') || (!brand.vip) ) ? 'items middle disabled pb_8 ' : 'items middle pb_8 ',
            //     subMenu    : true,
            //     border     : true,
            //     name       : 'VipClub',
            //     unique     : 'vipclub',
            //     access   : brand.vip && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)) && (brand.brandType !== Brand.BrandTypes.SUB_BRAND),
            // },
            // {
            //     link      : brand.brandType === Brand.BrandTypes.NORMAL && '/automation',
            //     logo      : brand.brandType === Brand.BrandTypes.NORMAL && SvgIcons.AutomationIcon,
            //     activelogo: brand.brandType === Brand.BrandTypes.NORMAL && SvgIcons.FilledAutomationIcon,
            //     className : ((brand.brandType === Brand.BrandTypes.NORMAL && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled' : 'items middle' ,
            //     // className : brand.brandType === Brand.BrandTypes.NORMAL && 'items mt_8 middle' ,
            //     name      : brand.brandType === Brand.BrandTypes.NORMAL && 'Automation',
            //     subMenu   : brand.brandType === Brand.BrandTypes.NORMAL && false,
            //     unique    : 'automation',
            //     access    : brand.brandType === Brand.BrandTypes.NORMAL && activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
            //     // children  : <LoyaltyChild />
            // },
            // {
            //     link      : '/walletscan',
            //     logo      : SvgIcons.WalletScanIcon,
            //     activelogo: SvgIcons.FillNavWalletScanIcon,
            //     // className : 'items middle topMarg mt_8' ,
            //     className : ((brand.brandType === Brand.BrandTypes.NORMAL && !brand.subscription) || (brand.brandType === Brand.BrandTypes.NORMAL && brand.subscription && brand.subscription.status !== 'active')) ? 'items middle disabled pt_8 pb_8' : 'items middle pt_8 pb_8' ,
            //     border    : true,
            //     subMenu   : true,
            //     name      : 'Wallet-Scan',
            //     unique    : 'walletscan',
            //     access    : activePermission.roles.some(role=>[User.Roles.ADMIN, User.Roles.MARKETER].includes(role)),
            //     // access    : activePermission.roles.some(role=>[User.Roles.ADMIN, User.Roles.MARKETER].includes(role) && ((brand.brandRedemptionType && brand.brandRedemptionType === RedemptionType.CASHIER) || (brand.brandType === Brand.BrandTypes.SHARED_LOYALITY) || (brand.brandType === Brand.BrandTypes.SUB_BRAND))),
            //     children  : <WalletScanChild />
            // },
            {
                link      : '/setting',
                logo      : SvgIcons.NavNewSettingIcon,
                className : 'items middle pt_8',
                subMenu   : true,
                name      : 'Settings',
                unique    : 'setting',
                access    : activePermission.roles.some(role=>[User.Roles.ADMIN].includes(role)),
                children  : <SettingChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
            },
        ])
    }

    useEffect(onLoad, [1])

    const handleClick = () => {
        setMobileCheck(!mobileCheck);
        layout.setLayout({expandedBar: false})
    }

    return (
        <div id="sidebar" >
            {console.log('dawa',brand)}
            
            
                <PartialSideBar 
                    items       = {items}
                    setItemsSub = {setItemsSub}
                />
        </div>
    )
}
